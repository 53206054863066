import React from 'react'
import { useGeneralSettings } from 'hooks/useGeneralSettings'
import { Modal } from '../Modal'
import { useSecondaryHeader } from 'contexts/components/SecondaryHeaderContext'
import { getPhoneIcon } from "assets/helper/icons"
import "./tracking-number-modal.module.scss"

export const TrackingNumberModal = () => {
  const { handleCloseModal } = useSecondaryHeader()
  const { trackingNumbers, companyName, customTrackingNumberModalHeading, usePhoneNumberNotTrackingNumber } = useGeneralSettings()
  const defaultHeading = `Call a ${companyName} location near you.`
  const modalHeading = customTrackingNumberModalHeading || defaultHeading
  return (
    <Modal onClose={handleCloseModal} variant="solid-single-column">
      <h3 className="fr-tracking-number-modal__heading">{modalHeading}</h3>
      
      <div className="fr-tracking-number-modal__body">
        {trackingNumbers.map(({label, svgIcon, trackingNumber, phoneNumber, id}) => {
          if (usePhoneNumberNotTrackingNumber && !phoneNumber) return ""
          if (!usePhoneNumberNotTrackingNumber && !trackingNumber) return ""
          return (
            <a 
              key={id} 
              href={`tel: ${usePhoneNumberNotTrackingNumber ? phoneNumber : trackingNumber}`} 
              className="fr-tracking-number-modal__item-wrapper"
            >
              {svgIcon && (
                <span
                  dangerouslySetInnerHTML={{__html: svgIcon}}
                  contentEditable="false"
                  className='phone-svg-container'
                />
              )}
              {!svgIcon && (
                <>
                  {getPhoneIcon()}
                </>
              )}
              
              <div className="fr-tracking-number-modal__item">
                {label && (
                  <span className="fr-tracking-number-modal__label">
                    {label}
                  </span>
                )}
                
                <span className="fr-tracking-number-modal__phone-number">
                  {usePhoneNumberNotTrackingNumber ? phoneNumber : trackingNumber}
                </span>
              </div>
            </a>
          )
        })}
      </div>
    </Modal>
  )
}
import React from 'react'
import { getPhoneIcon } from 'assets/helper/icons'
import { useGeneralSettings } from 'hooks/useGeneralSettings'

interface AnchorNumberProps {
  phoneNumber?: string
  trackingNumber?: string
  label?: string
  svgIcon?: string
  classNameModifier?: string
  showIcon?: boolean
}

export const AnchorNumber = ({phoneNumber, trackingNumber, label, svgIcon, classNameModifier = "", showIcon = true}: AnchorNumberProps) => {
  const {usePhoneNumberNotTrackingNumber} = useGeneralSettings()

  return (
    <a
      href={`tel: ${usePhoneNumberNotTrackingNumber ? phoneNumber : trackingNumber}`}
      className={`secondary-header__phone-link${classNameModifier}`}
    >
      {label && (
        <div className="secondary-header__extra-call-text">
          {label}
        </div>
      )}
      {(showIcon && svgIcon) && (
        <span
          dangerouslySetInnerHTML={{__html: svgIcon}}
          contentEditable="false"
          className='phone-svg-container'
        />
      )}
      {(showIcon && !svgIcon) && (
        <>
          {getPhoneIcon()}
        </>
      )}
      {usePhoneNumberNotTrackingNumber ? phoneNumber : trackingNumber}
    </a>
  )
}
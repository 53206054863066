import React from "react"
import { graphql } from "gatsby"
import {
  AnchorLink as GatsbyAnchorLink,
  AnchorLinkProps,
} from "gatsby-plugin-anchor-links"

interface AnchorLink extends AnchorLinkProps {}

export const AnchorLink = ({ children, ...rest }: AnchorLinkProps) => {
  return <GatsbyAnchorLink {...rest}>{children}</GatsbyAnchorLink>
}

export const anchorLink = graphql`
  fragment AnchorLinkFragment on kontent_item_anchor_button {
    id
    system {
      type
    }
    elements {
      button_text {
        value
      }
      anchor_reference {
        value {
          name
        }
      }
      custom_anchor_reference {
        value
      }
      linked_page {
        value {
          ... on kontent_item_page {
            id
            elements {
              slug {
                value
              }
            }
          }
        }
      }
    }
  }
`

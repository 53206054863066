import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { useSideNav } from "hooks/useSideNav"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { SubNav } from "./SubNav"
import "./side-nav.module.scss"
import { NavigationLinksOutput } from "contexts/settings/Navigation/navigation-types"
import { ExternalLink } from "../external-link/ExternalLink"
import { ImageBuilder } from "_types/AssetsImage"
import { Button } from "../button"
import { SocialIcons } from "../social-icons/SocialIcons"
import { getPhoneIcon, getSideNavCloseIcon } from "assets/helper/icons"
import { useWindowDimensions } from "hooks/useWindowDimensions"
import { useLayout } from "contexts/components/LayoutContext"
import { useIntegrationsSettings } from "hooks/useIntegrationsSettings"
import { useColorAreaSettings } from "../../hooks/useColorAreaSettings"
import { ButtonParser } from "components/button/ButtonParser"

interface SideNavProps {
  navItems: NavigationLinksOutput[]
  logo?: ImageBuilder
}

export const SideNav = ({ navItems }: SideNavProps) => {
  const { hasFieldroutesReviews, hasFieldroutesPortal, integrationButton } =
    useIntegrationsSettings()
  const { isNavOpen, handleCloseNav } = useSideNav()
  const {
    customCTAText,
    socialMedia,
    trackingNumbers,
    customTrackingNumberCtaButtonText,
    usePhoneNumberNotTrackingNumber,
  } = useGeneralSettings()
  const { linkedPagesUrlPathCodex, slug } = useLayout()
  const { height } = useWindowDimensions()
  const { sideNavBackgroundColor } = useColorAreaSettings()
  const [sideNavHovered, setSideNaveHovered] = useState(false)
  const scrollClass = height > 45 ? " side-nav--scroll-version" : ""

  const trackingPhoneNumber =
    trackingNumbers.length > 0 ? trackingNumbers[0]?.trackingNumber : ""
  const singlePhoneNumber =
    trackingNumbers.length > 0 ? trackingNumbers[0]?.phoneNumber : ""

  const contactNumber = usePhoneNumberNotTrackingNumber
    ? singlePhoneNumber
    : trackingPhoneNumber

  const handleCloseNavEscKeyDown = (
    event: globalThis.KeyboardEvent | React.KeyboardEvent<HTMLElement>
  ) => {
    const isEscKey = event.keyCode === 27
    if (isEscKey && isNavOpen) {
      handleCloseNav()
    }
  }
  const closeNav = () => {
    handleCloseNav()
    sideNavHovered ? setSideNaveHovered(false) : null
  }
  const overrideBackground = sideNavBackgroundColor
    ? ` ${sideNavBackgroundColor}--backgroundColor`
    : ""

  const trackingNumberCTAText =
    customTrackingNumberCtaButtonText || "Call Today"

  useEffect(() => {
    document.addEventListener("keydown", handleCloseNavEscKeyDown, false)

    return () => {
      document.removeEventListener("keydown", handleCloseNavEscKeyDown, false)
    }
  }, [])

  return (
    <>
      <aside
        onKeyDown={handleCloseNavEscKeyDown}
        onMouseEnter={() => {
          !sideNavHovered && setSideNaveHovered(true)
        }}
        className={`side-nav${
          !isNavOpen ? " side-nav--close-nav" : ""
        }${scrollClass}${overrideBackground}`}
      >
        <div className="fr-container side-nav__inner">
          {
            <Button
              variant="flashy-icon"
              onClick={closeNav}
              extraClassNames="side-nav__close-button"
              aria-label="close side menu"
            >
              {getSideNavCloseIcon()}
            </Button>
          }
          <ul className="side-nav__links">
            {navItems.map(
              ({ id, linkText, slug, childPages, externalLink }) => {
                if (childPages.length > 0) {
                  return (
                    <SubNav
                      key={id}
                      id={id}
                      parentSlug={slug}
                      childPages={childPages}
                      linkText={linkText}
                    />
                  )
                } else if (externalLink) {
                  return (
                    <ExternalLink
                      className="side-nav__link"
                      href={externalLink}
                      key={id}
                    >
                      {linkText}
                    </ExternalLink>
                  )
                } else {
                  if (!hasFieldroutesReviews && slug === "reviews") {
                    return null
                  }
                  const pageSlug = linkedPagesUrlPathCodex[slug] || "404"
                  return (
                    <li key={id}>
                      <Link
                        onClick={closeNav}
                        to={
                          pageSlug?.startsWith("/")
                            ? `${pageSlug}`
                            : `/${pageSlug}`
                        }
                        activeClassName="side-nav__link-active"
                        className="side-nav__link"
                      >
                        {linkText}
                      </Link>
                    </li>
                  )
                }
              }
            )}
            {hasFieldroutesPortal && (
              <li>
                <a
                  href="/login"
                  onClick={closeNav}
                  className={`side-nav__link ${
                    slug === "/login" && "side-nav__link-active"
                  }`}
                >
                  Login
                </a>
              </li>
            )}
          </ul>

          <p className="side-nav__cta">{customCTAText}</p>
          <div className="side-nav__buttons">
            {integrationButton && (
              <ButtonParser
                buttons={integrationButton}
                primaryColorVariant="solid-primary"
              />
            )}

            {trackingNumbers.length === 1 && contactNumber && (
              <div className="button--outline-ghost">
                <a
                  className="side-nav__call-tracking-link"
                  href={`tel: ${contactNumber}`}
                >
                  {getPhoneIcon()}
                  {trackingNumberCTAText}
                </a>
              </div>
            )}
          </div>
          {trackingNumbers.length > 1 && (
            <div className="side-nav__tracking-container">
              {trackingNumbers.map(
                ({ trackingNumber, id, phoneNumber, label }) => {
                  if (usePhoneNumberNotTrackingNumber && !phoneNumber) return ""
                  if (!usePhoneNumberNotTrackingNumber && !trackingNumber)
                    return ""
                  return (
                    <div key={id} className="side-nav__tracking-line">
                      {label && <p>{label}:</p>}
                      <a
                        href={`tel: ${
                          usePhoneNumberNotTrackingNumber
                            ? phoneNumber
                            : trackingNumber
                        }`}
                        className="side-nav__tracking-number"
                      >
                        {usePhoneNumberNotTrackingNumber
                          ? phoneNumber
                          : trackingNumber}
                      </a>
                    </div>
                  )
                }
              )}
            </div>
          )}
          <div className="side-nav__icons">
            {socialMedia.map(
              ({ linkUrl, mediaOutlet, customIcon, customOutletName, id }) => (
                <SocialIcons
                  href={linkUrl}
                  socialMediaOutlet={mediaOutlet}
                  customIcon={customIcon}
                  customOutletName={customOutletName}
                  key={id}
                />
              )
            )}
          </div>
        </div>
      </aside>
      <div
        className={`side-nav__overlay 
    ${isNavOpen ? "side-nav__overlay--open" : "side-nav__overlay--closed"}`}
        onClick={closeNav}
        onMouseEnter={() => {
          sideNavHovered && closeNav()
        }}
      ></div>
    </>
  )
}

import React from "react"
import { ImageBuilder } from "../../_types/AssetsImage"
import { getBackgroundImageUrlByBreakpoint } from "../../assets/helper/getBackgroundImageUrlByBreakpoint"
import { Button } from "../button"
import { GoogleReview } from "../google-reviews-banner/GoogleReview"
import { SkeletonElement } from "../skeleton/SkeletonElement"
import { TestimonialSkeleton } from "../skeleton/Testimonial/TestimonialSkeleton"
import { Testimonial } from "./Testimonial"
import "./testimonial.module.scss"
import { useWindowDimensions } from "hooks/useWindowDimensions"

interface TestimonialBannerProps {
  review: string
  rating: string
  reviewerName: string
  reviewerLocation: string
  hasFieldroutesReviews?: boolean
  hasReviewLoaded?: boolean
  backgroundImageUrl?: string
  avatar?: ImageBuilder
  googleReviewUrl?: string
  heading?: string
  isLandingPage?: boolean
}

export const TestimonialBanner = ({
  review,
  rating,
  reviewerName,
  reviewerLocation,
  hasFieldroutesReviews = false,
  avatar,
  googleReviewUrl,
  backgroundImageUrl,
  heading,
  hasReviewLoaded = true,
  isLandingPage=false,

}: TestimonialBannerProps) => {
  const backgroundImageCSSVariable = backgroundImageUrl
    ? getBackgroundImageUrlByBreakpoint({
        variableName: "testimonial-background-image-url",
        imageUrl: backgroundImageUrl,
      })
    : {}
  const { height } = useWindowDimensions()

  const googleReviewModifier = googleReviewUrl
    ? "fr-testimonial--has-google-review"
    : ""
  return (
    <section
      className={`fr-testimonial ${googleReviewModifier} ${
        backgroundImageUrl ? "fr-testimonial--withBackgroundImage" : ""
      } ${hasFieldroutesReviews ? "fr-testimonial--fadeIn" : ""}`}
      style={height > 10 ? backgroundImageCSSVariable : {}}
    >
      <div className="fr-container fr-testimonial__wrapper">
        {heading && <h2 className="fr-testimonial__heading">{heading}</h2>}
        {hasReviewLoaded ? (
          <Testimonial
            hasFieldroutesReviews={hasFieldroutesReviews}
            reviewerName={reviewerName}
            rating={rating}
            review={review}
            avatar={avatar}
            reviewerLocation={reviewerLocation}
          />
        ) : (
          <TestimonialSkeleton hasAvatar={!!avatar} hasRating={!!rating} />
        )}

        {isLandingPage !== true &&
          hasFieldroutesReviews &&
          !hasReviewLoaded && (
            <SkeletonElement
              type="text"
              additionalClassNames={"fr-testimonial-skeleton__button"}
            />
          )}

        {isLandingPage !== true && hasFieldroutesReviews && hasReviewLoaded && (

          <Button
            variant={"outline-ghost"}
            to="/reviews"
            extraClassNames="fr-testimonial__button"
          >
            Read All Reviews
          </Button>
        )}

        {googleReviewUrl && (
          <div className="fr-testimonial__footer">
            <GoogleReview url={googleReviewUrl} />
          </div>
        )} 
      </div>
    </section>
  )
}

import React from "react"
import { graphql, Link } from "gatsby"
import "./image-link.module.scss"
import { ExternalLink } from "../external-link/ExternalLink"
import { Image } from "../image/Image"

export const ImageLink = ({ imageLink }) => {
  const image = imageLink.image.value[0]
  const url = imageLink.url.value
  const internal_link = imageLink.internal_link?.value[0] || []

  if (internal_link.length > 0) {
    return (
      <Link to={`${internal_link.elements.slug.value}`}>
        <Image
          image={image}
          width={image.width}
          url={image.url}
          className="image-link"
        />
      </Link>
    )
  } else {
    return (
      <ExternalLink href={url}>
        <Image
          image={image}
          width={image.width}
          url={image.url}
          className="image-link"
        />
      </ExternalLink>
    )
  }
}

export const query = graphql`
  fragment ImageLinkFragment on kontent_item_image_link {
    id
    elements {
      image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      internal_link {
        value {
          ... on kontent_item_page {
            id
            elements {
              slug {
                value
              }
            }
          }
        }
      }
      url {
        value
      }
    }
  }
`

import React from "react"

interface DateBadgeProps {
  date: string
}

export const DateBadge = ({date}: DateBadgeProps) => {
  const dateJS = new Date(date);
  const dayOfTheMonth = dateJS.getDate()
  const shortMonth = dateJS.toLocaleString('en-US', { month: "short" }).toUpperCase()

  return (
    <span className="fr-blog-card__published-date-badge">
      <time dateTime={date}>
        <span>{dayOfTheMonth}</span>
        <br/>
        {shortMonth}
      </time>
    </span>
  )
}
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useGeneralSettings } from "../../hooks/useGeneralSettings"
import { ExternalLink } from "../external-link/ExternalLink"
import "./google-reviews.module.scss"

interface GoogleReviewProps {
  url: string
}

type GraphQLData = {
  average: {
    averageRating: number
    isDevelopment: boolean
    hasNoCompass: boolean
  }
  count: {
    totalReviews: number
  }
}

export const GoogleReview = ({ url }: GoogleReviewProps) => {
  const { companyName } = useGeneralSettings()

  const {average, count} = useStaticQuery<GraphQLData>(getData)
  
  const { averageRating, isDevelopment, hasNoCompass } = average
  const { totalReviews } = count

  if ((!isDevelopment && hasNoCompass) || averageRating === 0) return null

  const averageRatingFormatted = averageRating.toFixed(1)

  return (
    <article className="fr-google-review">
      {/* google logo here */}
      <div id="google-reviews">
        <div className="fr-google-review__wrapper">
          <p className="fr-google-review__headline">
              <strong>{companyName}</strong>
              {" received an average rating of "}
              <strong>{averageRatingFormatted}</strong>
              {" out of "}
              <strong>5 stars</strong>
              {" from "}
              <strong>{totalReviews} reviews</strong>
          </p>
          <div className="fr-google-review__details" style={{"--fr-google-review-rating": `${averageRating}`} as {[key: string]: string}}>
            <span
              className="fr-google-review__stars"
              aria-label={`Rating of this product is ${averageRatingFormatted} out of 5.`}
            >
              {averageRatingFormatted}
              <span className="fr-google-review__stars-gradient"></span>
            </span>
            <ExternalLink href={url}>
              Read Google Reviews
            </ExternalLink>
          </div>
        </div>
      </div>
    </article>
  )
}

const getData = graphql`
  query {
    average {
      averageRating
      isDevelopment
      hasNoCompass
    }
    count {
      totalReviews
    }
  }
`

export const query = graphql`
  fragment GoogleReviewsFragment on kontent_item_settings_elements {
    google_reviews_url {
      value
    }
  }
`

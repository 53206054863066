import React from "react"
import { Link } from "gatsby"
import { ExternalLink } from "../external-link/ExternalLink"
import "./footerCredits.module.scss"
import { NavigationLinksOutput } from "contexts/settings/Navigation/navigation-types"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { useLayout } from "contexts/components/LayoutContext"

interface CreditsProps {
  creditsNavigation: NavigationLinksOutput[]
}

export const Credits = ({ creditsNavigation }: CreditsProps) => {
  const { companyName, footerAdditionalInformation } = useGeneralSettings()
  const { linkedPagesUrlPathCodex } = useLayout()
  let date = new Date().getFullYear()
  return (
    <section className="fr-container credits">
      <div className="credits__wrapper">
        <div className="credits__content">
          © {companyName}. All Rights Reserved {date}.
        </div>
        {creditsNavigation.length > 0 && (
          <div className="credits__nav">
            {creditsNavigation?.map(navItem => {
              const { slug, linkText, externalLink } = navItem
              if (externalLink) {
                return (
                  <ExternalLink
                    key={linkText}
                    className="credits__nav-item"
                    href={externalLink}
                  >
                    {linkText}
                  </ExternalLink>
                )
              } else {
                const path = linkedPagesUrlPathCodex[slug] || "404"
                const newSlug = path.startsWith("/") ? path : `/${path}`
                return (
                  <Link
                    key={linkText}
                    to={newSlug}
                    className="credits__nav-item"
                  >
                    {linkText}
                  </Link>
                )
              }
            })}
          </div>
        )}
      </div>

      {footerAdditionalInformation &&
        footerAdditionalInformation.toString() !== "<p><br></p>" && (
          <div className="credits__additional-information">
            <RichTextElement value={footerAdditionalInformation} />
          </div>
        )}
    </section>
  )
}

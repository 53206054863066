import { useContext } from "react"
import { ExternalScriptsContext, ExternalScriptsContextData } from "../contexts/ExternalScriptsContext"

export const useExternalScripts = (): ExternalScriptsContextData => {
  const context = useContext(ExternalScriptsContext)

  if (!context) {
    throw new Error('useExternalScripts must be used within ExternalScriptsProvider');
  }

  return context
}
import React from 'react'
import { RichTextElement } from '@kentico/gatsby-kontent-components'

interface FormSubmissionMessageProps {
  className?: string
  heading?: string
  subHeading?: string
}

export const FormSubmissionMessage = ({heading, subHeading, className = ""}: FormSubmissionMessageProps) => {
  return (
    <div className={className} id="form-submitted">
        <h2>
          {!heading ||
          heading === ""
            ? "Thank You"
            : heading}
        </h2>
        <div style={{ textAlign: "center" }}>
          {!subHeading ||
          subHeading === "" ? (
            "Your Message has Been received"
          ) : (
            <RichTextElement value={subHeading} />
          )}
        </div>
    </div>
  )
}
import React, { useContext } from "react"
import { Link } from "gatsby"
import { getPhoneIcon, getLoginIcon } from "assets/helper/icons"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { useIntegrationsSettings } from "hooks/useIntegrationsSettings"
import { Button } from "components/button"
import { useSecondaryHeader } from "contexts/components/SecondaryHeaderContext"
import { TrackingNumberModal } from "components/modal/TrackingNumberModal/TrackingNumberModal"
import { AnchorNumber } from "./AnchorNumber"
import { NavigationSettingsContext } from "contexts/settings/Navigation/NavigationSettingsContext"

export const SecondaryHeaderLaptop = () => {
  const { handleOpenModal, isModalOpen } = useSecondaryHeader()
  const {
    hasFieldroutesReviews,
    hasFieldroutesPortal,
    customLinkTextFieldroutesReviews,
    customLinkTextFieldroutesPortal,
  } = useIntegrationsSettings()

  const {
    trackingNumbers,
    customTrackingNumberCtaButtonText,
    usePhoneNumberNotTrackingNumber,
  } = useGeneralSettings()

  const { secondaryHeaderNavigation } = useContext(NavigationSettingsContext)

  const trackingNumberCTAText =
    customTrackingNumberCtaButtonText || "Call Today"

  return (
    <>
      <div className="fr-container secondary-header__inner secondary-header__inner--laptop">
        <div className="secondary-header__portal-links">
          {secondaryHeaderNavigation.length > 0 &&
            secondaryHeaderNavigation.map(
              ({ linkText, slug, externalLink }, i) => {
                if (externalLink.length > 0) {
                  return (
                    <a
                      key={`shn-${i}`}
                      href={externalLink}
                      target="_blank"
                      className="secondary-header__portal-link"
                    >
                      {linkText}
                    </a>
                  )
                } else
                  return (
                    <Link
                      key={`shn-${i}`}
                      to={`/${slug}`}
                      className="secondary-header__portal-link"
                    >
                      {linkText}
                    </Link>
                  )
              }
            )}
          {hasFieldroutesReviews && (
            <Link to="/reviews" className="secondary-header__portal-link">
              {customLinkTextFieldroutesReviews
                ? customLinkTextFieldroutesReviews
                : "Customer Reviews"}
            </Link>
          )}
          {secondaryHeaderNavigation.map(({ linkText, slug }) => {
            <Link to={`/${slug}`} className="secondary-header__portal-link">
              {linkText}
            </Link>
          })}
          {hasFieldroutesPortal && (
            <a href="/login" className="secondary-header__portal-link">
              {getLoginIcon()}
              {customLinkTextFieldroutesPortal
                ? customLinkTextFieldroutesPortal
                : "Customer Login"}
            </a>
          )}
        </div>

        {trackingNumbers.length > 0 &&
          trackingNumbers.length <= 3 &&
          trackingNumbers.map(
            ({ trackingNumber, phoneNumber, id, label }, i) => {
              if (usePhoneNumberNotTrackingNumber && !phoneNumber) return ""
              if (!usePhoneNumberNotTrackingNumber && !trackingNumber) return ""
              return (
                <AnchorNumber
                  key={id}
                  classNameModifier={
                    i === 0 ? " secondary-header__phone-link--first" : ""
                  }
                  phoneNumber={phoneNumber}
                  trackingNumber={trackingNumber}
                  label={label}
                  showIcon={false}
                />
              )
            }
          )}
        {trackingNumbers.length >= 4 && (
          <>
            <Button
              variant="flashy-icon"
              extraClassNames="secondary-header__phone-button"
              onClick={handleOpenModal}
            >
              {getPhoneIcon()}
              {trackingNumberCTAText}
            </Button>
            {!isModalOpen &&
              trackingNumbers.map(
                ({ trackingNumber, phoneNumber, id, label }) => {
                  if (usePhoneNumberNotTrackingNumber && !phoneNumber) return ""
                  if (!usePhoneNumberNotTrackingNumber && !trackingNumber)
                    return ""
                  return (
                    <AnchorNumber
                      key={id}
                      classNameModifier="--hidden"
                      phoneNumber={phoneNumber}
                      trackingNumber={trackingNumber}
                      label={label}
                      showIcon={false}
                    />
                  )
                }
              )}
          </>
        )}
      </div>
      {trackingNumbers.length >= 4 && isModalOpen && (
        <div className={`fr-tracking-number-modal`}>
          <TrackingNumberModal />
        </div>
      )}
    </>
  )
}

import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { useExternalScripts } from "../../hooks/useExternalScripts"
import { PestroutesHelper } from "assets/helper/setTrackingCookies"
import { appendScript } from "../../assets/helper/appendScript"
import { FieldsParser } from "./FieldsParser"
import {
  InitialFormValues,
  ValidationObject,
  Fields,
} from "./formBuilder-types"
import { graphql } from "gatsby"
import { FormFieldsContext } from "../../contexts/FormFieldsContext"
import { useGeneralSettings } from "../../hooks/useGeneralSettings"
import { FormErrorMessage } from "./FormErrorMessage"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { FormSubmissionMessage } from "./FormSubmissionMessage"
import { Button } from "../button"
import "./form-builder.module.scss"
import "./ppc-form-builder.module.scss"
import { getInitialFormValues } from "./utils/getInitialFormValues"
import { useLogos } from "../../hooks/queries/settings/useLogos"
import { FormBuilderContext } from "../../contexts/components/FormBuilderContex"
import { FormFieldGroup } from "./FormFieldGroup"

interface FormBuilderProps {
  heading?: string
  subHeading?: string
  fields: Fields
  formGridStyle: string
  ctaText?: string
  isBoxed?: boolean
  submissionMessageHeading?: string
  submissionMessageBody?: string
  customId?: string
  isLandingPage?: boolean
  customTrackingNumber?: string
  disclaimer?: string
}

export const FormBuilder = ({
  heading,
  subHeading,
  fields,
  formGridStyle,
  ctaText,
  submissionMessageHeading,
  submissionMessageBody,
  isBoxed,
  customId,
  isLandingPage = false,
  customTrackingNumber,
  disclaimer,
}: FormBuilderProps) => {
  const formRef = useRef<HTMLFormElement>(null)
  const { isRecaptchaScriptsLoaded, handleSetRecaptcha } = useExternalScripts()
  const { fullName, email } = useContext(FormFieldsContext)
  const {
    siteUrl,
    recapKey,
    trackingNumbers,
    usePhoneNumberNotTrackingNumber,
  } = useGeneralSettings()
  const trackingPhoneNumber =
    trackingNumbers.length > 0 ? trackingNumbers[0]?.trackingNumber : ""
  const singlePhoneNumber =
    trackingNumbers.length > 0 ? trackingNumbers[0]?.phoneNumber : ""

  const contactNumber = usePhoneNumberNotTrackingNumber
    ? singlePhoneNumber
    : trackingPhoneNumber

  const [hasError, setHasError] = useState(false)
  const [firstRender, setFirstRender] = useState(false)
  const [hasFormSubmitted, setHasFormSubmitted] = useState(false)
  const [formSubmitting, setFormSubmitting] = useState(false)

  const initialFormValues = {} as InitialFormValues
  const validationObject = {} as ValidationObject

  const reCaptchaFocus = useCallback(() => {
    appendScript({
      id: "recaptcha",
      scriptToAppend: `https://www.google.com/recaptcha/api.js?render=${recapKey}`,
      isAsync: true,
    })

    // remove focus to avoid js error:
    // Uncaught Error: reCAPTCHA has already been rendered in this element at Object.kh
    formRef.current &&
      formRef.current.removeEventListener("focus", reCaptchaFocus, true)

    handleSetRecaptcha(true)
  }, [recapKey, formRef, handleSetRecaptcha])

  useEffect(() => {
    if (
      firstRender &&
      !isRecaptchaScriptsLoaded &&
      !(window as any).grecaptcha
    ) {
      formRef.current?.addEventListener("focus", reCaptchaFocus, true)
    } else {
      setFirstRender(true)
    }
  }, [isRecaptchaScriptsLoaded, firstRender, reCaptchaFocus])

  fields?.modular_content.length > 0 &&
    getInitialFormValues({
      fields: fields.modular_content,
      validationObject,
      initialFormValues,
      fullName,
      email,
    })

  const handleOnSubmit = async (values: object) => {
    if (!formSubmitting) {
      setFormSubmitting(true)
      ;(window as any).grecaptcha.ready(() => {
        ;(window as any).grecaptcha
          .execute(recapKey, { action: "submit" })
          .then((token: string) => {
            let myHeaders = new Headers()
            myHeaders.append("Content-Type", "text/plain")
            myHeaders.append("Referer", `${siteUrl}`)
            let raw = JSON.stringify({
              ...values,
              "google-recaptcha-token": token,
              campaignTracking: {
                source: PestroutesHelper.getCookie("utm_source"),
                campaign: PestroutesHelper.getCookie("utm_campaign"),
                medium: PestroutesHelper.getCookie("utm_medium"),
                term: PestroutesHelper.getCookie("utm_term"),
                content: PestroutesHelper.getCookie("utm_content"),
              },
            })
            let params: RequestInit = {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow",
            }
            fetch(
              "https://control-prod.lobstermarketing.com/lobster-sites/contact-us",
              params
            )
              .then(response => response)
              .then(response => {
                if (response.status >= 200 && response.status < 300) {
                  console.log("Lead Success: Status ", response.status)
                  setHasFormSubmitted(true)
                  setFormSubmitting(false)
                  setHasError(false)
                  if (window.dataLayer) {
                    window.dataLayer.push({
                      event: "FormSubmitConversionEvent",
                    })
                  }
                } else {
                  console.log("status", response.status)
                  console.log("error", response)
                  setHasError(true)
                  setFormSubmitting(false)
                }
              })
              .catch(error => {
                console.log("error", error)
                setHasError(true)
                setFormSubmitting(false)
              })
          })
          .catch((error: unknown) => {
            console.log("error", error)
            setHasError(true)
            setFormSubmitting(false)
          })
      })
    }
  }

  const sectionBgClassName = isBoxed
    ? "alternate-background-color"
    : "default-background-color"
  const boxedWrapperModifier = isBoxed
    ? " fr-form-builder__wrapper--is-boxed"
    : ""

  const getFormHeader = (isBoxed?: boolean) => {
    if (isBoxed) {
      const { headerLogo } = useLogos()
      return (
        <div className={"fr-form-builder__boxed-heading-container"}>
          <img
            className="base-logo"
            src={`${headerLogo.url}?q=60&w=200`}
            alt={headerLogo.description}
            loading="lazy"
          />
          <div className="fr-form-builder__boxed-header">
            <h2>{heading}</h2>
            {subHeading && <RichTextElement value={subHeading} />}
          </div>
        </div>
      )
    } else {
      return (
        <div className="fr-form-builder__heading-container">
          <h2>{heading}</h2>
          {subHeading && <RichTextElement value={subHeading} />}
        </div>
      )
    }
  }
  const formId = customId || "form-banner"
  return (
    <section
      className={
        isLandingPage
          ? `fr-form-builder ppc`
          : `fr-form-builder ${sectionBgClassName} u-content-block-padding`
      }
      id={formId}
    >
      {isLandingPage && heading && (
        <h2 className={`ppc__heading`}> {heading} </h2>
      )}
      <div
        className={
          isLandingPage
            ? `ppc__landing-form-inner ${
                hasFormSubmitted ? "fr-form-builder__wrapper--hide" : ""
              } `
            : `fr-form-builder__wrapper${boxedWrapperModifier} fr-container ${
                hasFormSubmitted ? "fr-form-builder__wrapper--hide" : ""
              }`
        }
      >
        {hasError && <FormErrorMessage />}

        {!isLandingPage && heading && getFormHeader(isBoxed)}

        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          validationSchema={Yup.object(validationObject)}
          onSubmit={values => handleOnSubmit(values)}
        >
          {({ errors, touched }) => {
            return (
              <FormBuilderContext.Provider value={{ errors, touched }}>
                <Form ref={formRef}>
                  <div className={formGridStyle}>
                    {fields?.modular_content?.map(
                      ({ id, elements, __typename }) => {
                        if (!elements) return
                        if (__typename === "kontent_item_form_field") {
                          const fieldType = elements?.field_type?.value
                            ? elements.field_type.value[0]?.codename!
                            : ""

                          return (
                            <FieldsParser
                              key={id}
                              fieldType={fieldType}
                              elements={elements}
                            />
                          )
                        } else if (
                          __typename === "kontent_item_form_field_group"
                        ) {
                          return (
                            <FormFieldGroup
                              key={id}
                              sectionDetails={elements.section_details}
                              fields={elements.fields}
                            />
                          )
                        }
                      }
                    )}
                  </div>
                  {disclaimer && disclaimer !== "<p><br></p>" && (
                    <RichTextElement value={disclaimer} />
                  )}
                  <Button
                    type="submit"
                    variant="solid-accent"
                    extraClassNames="fr-form-builder__button"
                  >
                    {!formSubmitting ? ctaText : "Submitting..."}
                  </Button>
                </Form>
              </FormBuilderContext.Provider>
            )
          }}
        </Formik>
        {isLandingPage && (
          <div className="ppc__phone-wrapper">
            <div className="ppc__call-today">Or Call Today</div>
            <a
              href={`tel: ${
                customTrackingNumber ? customTrackingNumber : contactNumber
              }`}
              className="ppc__tracking"
            >
              {customTrackingNumber ? customTrackingNumber : contactNumber}
            </a>
          </div>
        )}
      </div>
      {hasFormSubmitted && (
        <FormSubmissionMessage
          className={`fr-form-builder__wrapper${boxedWrapperModifier} ppc__landing-form-inner `}
          heading={submissionMessageHeading}
          subHeading={submissionMessageBody}
        />
      )}
    </section>
  )
}

export const query = graphql`
  fragment FormFragment on kontent_item_form {
    system {
      type
    }
    id
    elements {
      heading {
        value
      }
      sub_heading {
        links {
          link_id
          url_slug
        }
        value
      }
      submission_message_body {
        value
      }
      submission_message_heading {
        value
      }
      submit_button_text {
        value
      }
      fields {
        modular_content {
          ...FormFieldFragment
          ...FormFieldGroupFragment
        }
      }
      form_column_layout {
        value {
          codename
        }
      }
      is_boxed_layout {
        value {
          codename
        }
      }
      custom_id {
        value
      }
      disclaimer {
        value
      }
      background_image {
        value {
          url
          description
        }
      }
    }
  }
`

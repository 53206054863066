import React from "react"
import { ImageElement } from "@kentico/gatsby-kontent-components"
import "./repeating-image.module.scss"

export const RepeatingImage = ({ images }) => {
  const image = images.single_image.value[0]
  return (
    <div className="image-wrapper">
      <ImageElement
        image={image}
        url={image.url}
        alt={image.description}
        className="image"
        height={image.height}
        width={image.width}
      />
    </div>
  )
}

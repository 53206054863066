import { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { ImageItem } from "@kentico/gatsby-kontent-components/dist/image-element"

interface ImageBuilder extends ImageItem {
  url: string
  description: string
}

type Image = {
  value: ImageBuilder[]
}


interface KontentItemSettings {
  header_logo: Image
  footer_logo: Image
  side_nav_logo: Image
  favicon: Image
}

interface LogoData {
  elements: KontentItemSettings
}

interface Logos {
  headerLogo: ImageBuilder
  footerLogo: ImageBuilder
  sideNavLogo: ImageBuilder
  faviconLogo: ImageBuilder
}


export const useLogos = (): Logos => {
  const data = useStaticQuery<{ kontentItemSettings: LogoData }>(getData)

  const { kontentItemSettings } = data

  const {
    header_logo,
    footer_logo,
    side_nav_logo,
    favicon
  } = kontentItemSettings?.elements!

  const headerLogo = useMemo(() => {
    return header_logo?.value?.map(logo => {
      return {
        ...logo,
      }
    })[0]
  }, [header_logo])

  const footerLogo = useMemo(() => {
    return footer_logo?.value?.map(logo => {
      return {
        ...logo,
      }
    })[0]
  }, [footer_logo])

  const sideNavLogo = useMemo(() => {
    return side_nav_logo?.value?.map(logo => {
      return {
        ...logo,
      }
    })[0]
  }, [side_nav_logo])

  const faviconLogo = useMemo(() => {
    return favicon?.value?.map(logo => {
      return {
        ...logo,
      }
    })[0]
  }, [favicon])

  return {
    headerLogo,
    footerLogo,
    sideNavLogo,
    faviconLogo
  }
}

const getData = graphql`
  query getLogoSettingsData {
    kontentItemSettings {
      elements {
        header_logo {
          value {
            ...KenticoAssetElementFragment
          }
        }
        footer_logo {
          value {
            ...KenticoAssetElementFragment
          }
        }
        side_nav_logo {
          value {
            ...KenticoAssetElementFragment
          }
        }
        favicon {
          value {
            ...KenticoAssetElementFragment
          }
        }
      }
    }
  }
`

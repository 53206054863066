import React, { AnchorHTMLAttributes } from "react"
import { ReactNode } from "react"

interface ExternalLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: ReactNode
}

export const ExternalLink = ({ children, ...rest }: ExternalLinkProps) => {
  return (
    <a target="_blank" rel="noopener noreferrer" {...rest}>
      {children}
    </a>
  )
}

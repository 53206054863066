import { useContext } from "react"
import { NavigationSettingsContext } from "../contexts/settings/Navigation/NavigationSettingsContext"
import { NavigationSettingsContextData } from "../contexts/settings/Navigation/navigation-types"

export const useNavigationItems = (): NavigationSettingsContextData => {
  const context = useContext(NavigationSettingsContext)

  if (!context) {
    throw new Error("useNavigationItems must be used within NavigationSettingsProvider")
  }

  return context
}

import React, { createContext, useContext } from 'react'
import { FormikErrors, FormikTouched } from 'formik'
import { InitialFormValues } from '../../components/forms/formBuilder-types'


type FormBuilderContextData = {
  errors: FormikErrors<InitialFormValues>
  touched: FormikTouched<InitialFormValues>
}

export const FormBuilderContext = createContext({} as FormBuilderContextData)

export function useFormBuilder() {
  const context = useContext(FormBuilderContext)

  if (!context) {
    throw new Error("useFormBuilder must be used within FormBuilderContext")
  }

  return context
}

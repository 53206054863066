import * as Yup from "yup"
import { ValidationObject } from "../formBuilder-types"

interface BuildValidationSchemaParams {
  validationObject: ValidationObject
  field: string
  required: string
}

export const buildValidationSchema = ({ field, required, validationObject }: BuildValidationSchemaParams) => {
  const ONE_DAY_MS = 86400000
  const YESTERDAY = new Date(Date.now() - ONE_DAY_MS)

  switch (field) {
    case "preferred_date":
      required === "yes"
        ? (validationObject[field] = Yup.date()
            .min(YESTERDAY, "Date cannot be in the past")
            .required("Required"))
        : (validationObject[field] = Yup.date().min(
            YESTERDAY,
            "Date cannot be in the past"
          ))
      break
    case "email":
      required === "yes"
        ? (validationObject[field] = Yup.string()
            .email("invalid email address")
            .required("Required"))
        : (validationObject[field] = Yup.string().email(
            "invalid email address"
          ))
      break
    case "phone":
      required === "yes"
        ? (validationObject[field] = Yup.string()
            .matches(
              /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
              "Please Enter A valid Phone Number"
            )
            .required("Required"))
        : (validationObject[field] = Yup.string().matches(
            /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            "Please Enter A valid Phone Number"
          ))
      break
    case "zip":
      required === "yes"
        ? (validationObject[field] = Yup.string()
            .matches(/^\d{5}$/, "Please enter a valid zip code")
            .required("Required"))
        : (validationObject[field] = Yup.string().matches(
            /^\d{5}$/,
            "Please enter a valid zip code"
          ))
      break
    default:
      required === "yes"
        ? (validationObject[field] = Yup.string().required("Required"))
        : (validationObject[field] = Yup.string())
      break
  }
}
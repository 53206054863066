import React, { ReactNode } from "react"
import { Link } from "gatsby"
import HamburgerMenu from "./HamburgerMenu"
import { Button } from "../button"
import { useSideNav } from "../../hooks/useSideNav"
import { ImageBuilder } from "../../_types/AssetsImage"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import "./header.module.scss"
import { getSideNavCloseIcon } from "../../assets/helper/icons"

interface HeaderProps {
  logo: ImageBuilder
  phone?: string
  sticky?: boolean
  children?: ReactNode
  hasToggleMenu?: boolean
  isLandingPage?: boolean
}

export const Header = ({
  logo,
  hasToggleMenu = true,
  children,
  isLandingPage = false,
}: HeaderProps) => {
  const { height } = useWindowDimensions()
  const { isNavOpen, handleCloseNav } = useSideNav()
  let scrollClass = ""

  if (height > 45) scrollClass = "fr-header--scroll-version"

  const toggleMenuAndCloseButton = () => {
    if (!isNavOpen) {
      return <HamburgerMenu />
    } else {
      return (
        <Button
          variant="flashy-icon"
          onClick={handleCloseNav}
          extraClassNames="fr-header__close-button"
          aria-label="close side menu"
        >
          {getSideNavCloseIcon()}
        </Button>
      )
    }
  }

  return (
    <header
      className={
        !isLandingPage
          ? `fr-header ${scrollClass}`
          : `fr-header fr-header__landing-page`
      }
    >
      <div className="fr-container fr-header__inner">
        {!isLandingPage && (
          <Link to="/" className={`base-logo`}>
            <img
              src={`${logo.url}?q=60&w=200`}
              alt={logo.description}
              loading="eager"
            />
          </Link>
        )}
        {isLandingPage && (
          <img
            src={`${logo.url}?q=60&w=200`}
            alt={logo.description}
            loading="eager"
            className="base-logo"
          />
        )}
        <div className="fr-header__right">
          {children}
          {hasToggleMenu && toggleMenuAndCloseButton()}
        </div>
      </div>
    </header>
  )
}

export const PestroutesHelper = {
  generateUUID: function () {
    let d = new Date().getTime()
    if (
      typeof performance !== "undefined" &&
      typeof performance.now === "function"
    ) {
      d += performance.now()
    }
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        let r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16)
      }
    )
  },

  generateSessionID: function () {
    return (
      Math.floor(Math.random() * (999 - 111)) +
      111 +
      "" +
      Math.floor(new Date().getTime() / 1000)
    )
  },

  getCookie: function (cname: string) {
    let name = cname + "="
    let ca = document.cookie.split(";")
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === " ") {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return decodeURIComponent(c.substring(name.length, c.length))
      }
    }
    return ""
  },

  checkCookie: function (cname: string) {
    return this.getCookie(cname) !== ""
  },

  setCookie: function (
    cname: string,
    cvalue: string | number | boolean,
    exdays
  ) {
    if (typeof cvalue === "object") {
      cvalue = JSON.stringify(cvalue)
    }
    cvalue = encodeURIComponent(cvalue)
    document.cookie = cname + "=" + cvalue + ";session;path=/"
  },

  getUrlParameter: function (sParam) {
    let sPageURL = decodeURIComponent(window.location.search.substring(1))
    let sURLVariables = sPageURL.split("&")
    let sParameterName

    for (let i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=")

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : sParameterName[1]
      }
    }
  },
}

export const setTrackingCookies = () => {
  let url = document.referrer
  let medium: string | true = ""
  let source: string | true = ""
  switch (url) {
    case "https://www.google.com":
      source = "google"
      medium = "organic"
      break
    case "https://www.bing.com":
      source = "bing"
      medium = "organic"
      break
    case "https://search.yahoo.com/":
      source = "yahoo"
      medium = "organic"
      break
    case "https://duckduckgo.com/":
      source = "duckduckgo"
      medium = "organic"
      break
    case "":
      source = "(direct)"
      medium = "none"
      break
    default:
      let temp = url.replace(/(^\w+:|^)\/\//, "")
      source = temp.replace(/^www\./, "")
      medium = "referral"
  }
  if (!PestroutesHelper.checkCookie("utm_campaign")) {
    let utmcampaign = PestroutesHelper.getUrlParameter("utm_campaign")
    let campaign = typeof utmcampaign === "undefined" ? "" : utmcampaign
    PestroutesHelper.setCookie("utm_campaign", campaign, 3)
  }
  if (!PestroutesHelper.checkCookie("utm_source")) {
    let gclid = PestroutesHelper.getUrlParameter("gclid")
    if (typeof gclid !== "undefined") {
      source = "google"
    } else {
      let utmsource = PestroutesHelper.getUrlParameter("utm_source")
      source = typeof utmsource === "undefined" ? source : utmsource
    }
    PestroutesHelper.setCookie("utm_source", source, 3)
  }
  if (!PestroutesHelper.checkCookie("utm_medium")) {
    let gclid = PestroutesHelper.getUrlParameter("gclid")
    if (typeof gclid !== "undefined") {
      medium = "cpc"
    } else {
      let utmmedium = PestroutesHelper.getUrlParameter("utm_medium")
      medium = typeof utmmedium === "undefined" ? medium : utmmedium
    }
    PestroutesHelper.setCookie("utm_medium", medium, 3)
  }
  if (!PestroutesHelper.checkCookie("utm_term")) {
    let utmterm = PestroutesHelper.getUrlParameter("utm_term")
    let term = typeof utmterm === "undefined" ? "" : utmterm
    PestroutesHelper.setCookie("utm_term", term, 3)
  }
  if (!PestroutesHelper.checkCookie("utm_content")) {
    let utmcontent = PestroutesHelper.getUrlParameter("utm_content")
    let content = typeof utmcontent === "undefined" ? "" : utmcontent
    PestroutesHelper.setCookie("utm_content", content, 3)
  }
}

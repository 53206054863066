import React, { ButtonHTMLAttributes, ReactNode, ComponentType } from "react"
import { graphql, navigate } from "gatsby"
import { IconBaseProps } from "react-icons/lib"
import { useSideNav } from "../../hooks/useSideNav"

type ButtonWithoutClassName = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "className"
>

export interface ButtonProps extends ButtonWithoutClassName {
  children: ReactNode
  variant:
    | "outline-primary"
    | "outline-secondary"
    | "solid-primary"
    | "solid-secondary"
    | "primary-page-link"
    | "ghost-page-link"
    | "tab"
    | "flashy-icon"
    | "outline-ghost"
    | "outline-cta-ghost"
    | "solid-accent"
  extraClassNames?: string
  to?: string
  leftIcon?: ComponentType<IconBaseProps>
  rightIcon?: ComponentType<IconBaseProps>
  ctaOverride?: boolean
}

export const Button = ({
  children,
  variant,
  to,
  ctaOverride,
  extraClassNames = "",
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  ...rest
}: ButtonProps) => {
  const { isNavOpen, handleCloseNav } = useSideNav()
  const variantClassName = `button--${variant}`
  const classNames = extraClassNames
    ? `${variantClassName} ${extraClassNames}`
    : variantClassName
  const isButtonOverride = ctaOverride ? `button--solid-secondary` : classNames
  if (to) {
    const goToPage = () => {
      if (isNavOpen) {
        handleCloseNav()
      }
      navigate(`${to}`)
    }

    return (
      <button
        role="button"
        {...rest}
        className={isButtonOverride}
        onClick={goToPage}
      >
        {LeftIcon && <LeftIcon className="left-icon" />}
        {children}
        {RightIcon && <RightIcon className="right-icon" />}
      </button>
    )
  }

  return (
    <button role="button" {...rest} className={isButtonOverride}>
      {LeftIcon && <LeftIcon className="left-icon" />}
      {children}
      {RightIcon && <RightIcon className="right-icon" />}
    </button>
  )
}

export const query = graphql`
  fragment ButtonFragment on kontent_item_button {
    id
    system {
      type
    }
    elements {
      button_text {
        value
      }
      external_link {
        value
      }
      linked_page {
        value {
          ... on kontent_item_page {
            id
            elements {
              slug {
                value
              }
            }
          }
        }
      }
      use_main_office_call_tracking_number {
        value {
          codename
        }
      }
      custom_call_tracking_number {
        value
      }
      cta_override {
        value {
          codename
        }
      }
    }
  }
`

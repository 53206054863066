import { graphql } from "gatsby"
import React from "react"
import { AffiliationSlider } from "../affiliation-slider/AffiliationSlider"
import { BlogPostEdge } from "../blog/BlogBanner/blog-banner-types"
import { BlogBanner } from "../blog/BlogBanner/BlogBanner"
import { CTABanner } from "../cta-banner/CTABanner"
import { FormBuilder } from "../forms/FormBuilder"

interface GlobalFooterItemsProps {
  blocks: any
  meta?: any
  relatedArticles: BlogPostEdge[]
  customTrackingNumber?: string
  isLandingPage?: boolean
}

export const GlobalFooterItems = ({
  blocks,
  meta,
  relatedArticles,
  customTrackingNumber,
  isLandingPage = false,
}: GlobalFooterItemsProps) => {
  const {
    cta_heading,
    description,
    body,
    background_image,
    button,
    logo_slider_heading,
    logo_slider,
    blog_banner_heading,
    sub_heading,
    fields,
    custom_id,
    disclaimer,
  } = blocks

  const getCtaBanner = () => {
    if (cta_heading) {
      return (
        <CTABanner
          background={background_image?.value[0]}
          heading={cta_heading.value}
          description={description?.value}
          body={body}
          buttons={button}
          customTrackingNumber={customTrackingNumber}
          isLandingPage={isLandingPage}
        />
      )
    }
  }

  const getFormBanner = () => {
    const customId = custom_id?.value === "" ? "form-banner" : custom_id?.value
    if (fields) {
      return (
        <FormBuilder
          heading={blocks?.heading?.value}
          subHeading={blocks?.sub_heading?.value}
          ctaText={blocks?.submit_button_text?.value}
          submissionMessageHeading={blocks?.submission_message_heading?.value}
          submissionMessageBody={blocks?.submission_message_body?.value}
          fields={fields}
          formGridStyle={"form-grid-3"}
          isBoxed
          customId={customId}
          disclaimer={disclaimer.value}
        />
      )
    }
  }

  const getAffiliationBanner = () => {
    if (logo_slider_heading) {
      return (
        <AffiliationSlider
          heading={logo_slider_heading.value}
          subHeading={sub_heading.value}
          images={logo_slider?.value}
        />
      )
    }
  }

  const getBlogBanner = () => {
    if (blog_banner_heading) {
      return (
        <BlogBanner
          heading={blog_banner_heading.value}
          subHeading={sub_heading.value}
          relatedArticles={relatedArticles}
          parentSlug={meta && meta.parentSlug ? meta.parentSlug : ""}
        />
      )
    }
  }
  return (
    <>
      {getCtaBanner()}
      {getFormBanner()}
      {getAffiliationBanner()}
      {getBlogBanner()}
    </>
  )
}

export const query = graphql`
  fragment GlobalFooterItemsFragment on kontent_item_rich_text_element_value {
    modular_content {
      system {
        type
      }
      ...BlogBannerFragment
      ...CTABannerFragment
      ...FormFragment
      ...LogoSliderFragment
    }
  }
`

import { useContext } from "react"
import { WindowDimensionsContext, WindowDimensionsContextData } from "../contexts/WindowDimensionsContext"

export const useWindowDimensions = (): WindowDimensionsContextData => {
  const context = useContext(WindowDimensionsContext)

  if (!context) {
    throw new Error('useWindowDimensions must be used within WindowDimensionsProvider');
  }

  return context
}
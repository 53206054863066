import { useContext } from "react"
import { IntegrationsSettingsContext } from "../contexts/settings/Integrations/IntegrationsSettingsContext"
import { IntegrationsSettingsContextData } from "../contexts/settings/Integrations/integrationsSettings-types"

export const useIntegrationsSettings = (): IntegrationsSettingsContextData => {
  const context = useContext(IntegrationsSettingsContext)

  if (!context) {
    throw new Error('useIntegrationsSettings must be used within IntegrationsSettingsProvider');
  }

  return context
}
import { Field } from "formik"
import { graphql } from "gatsby"
import React from "react"
import { FieldContainer } from "./FieldContainer"
import { KontentItemFormFieldElements } from "./formBuilder-types"

const PLACEHOLDER_MESSAGE: { [key: string]: string } = {
  full_name: "Tony Smith",
  zip: "96000",
  phone: "(888) 888-888",
}

interface FieldsParserProps {
  fieldType: string
  elements: KontentItemFormFieldElements
}

export const FieldsParser = ({ fieldType, elements }: FieldsParserProps) => {
  const { form_field, label, required, hidden_field_value, placeholder } =
    elements

  const formFieldCodename = form_field?.value
    ? form_field?.value[0]?.codename!
    : ""

  const isCustomFormField = formFieldCodename === "custom"

  const fieldLabel = label?.value!
  const customFieldLabel = fieldLabel?.replace(/\s+/g, "")!

  const htmlFor = isCustomFormField ? customFieldLabel : formFieldCodename
  const isExistingCustomer = form_field.value[0].codename === "existing_customer" ? true : false
  const heardAboutus = form_field.value[0].codename === "heard_about_us" ? true : false
  const nameCheck = isExistingCustomer ? "AreYouanExistingCustomer" : heardAboutus ? "HowDidYouHearAboutUs" : htmlFor
  const isRequired = required?.value ? required?.value[0]?.codename! : ""

  let emailDatalayerValue
  const handleEmailDatalayer = (e) => {
    emailDatalayerValue = e.target.value
    if (window.dataLayer) {
      window.dataLayer.push({'emailAddress': emailDatalayerValue});
    }
  }
  
  switch (fieldType) {
    case "date":
      return (
        <FieldContainer
          htmlFor={htmlFor}
          label={fieldLabel}
          isFieldRequired={isRequired}
          className="fr-form-builder__field"
        >
          <Field name={htmlFor} type="date" required />
        </FieldContainer>
      )

    case "email":
      return (
        <FieldContainer
          htmlFor={htmlFor}
          label={fieldLabel}
          isFieldRequired={isRequired}
          className="fr-form-builder__field"
        >
          <Field
            id="emailField"
            name={htmlFor}
            type="email"
            placeholder={placeholder?.value || "name@email.com"}
            onBlur = {handleEmailDatalayer}
          />
        </FieldContainer>
      )

    case "long_text":
      return (
        <FieldContainer
          htmlFor={htmlFor}
          label={fieldLabel}
          isFieldRequired={isRequired}
          className="fr-form-builder__field fr-form-builder__field--text-area"
        >
          <Field
            name={nameCheck}
            as="textarea"
            placeholder={placeholder?.value || "Message"}
          />
        </FieldContainer>
      )

    case "select":
      const optsArr = elements?.options?.value?.split(",")!
      return (
        <FieldContainer
          htmlFor={htmlFor}
          label={fieldLabel}
          isFieldRequired={isRequired}
          className="fr-form-builder__field"
        >
          <Field name={nameCheck} as="select">
            {optsArr.map((option: string, index: number) => {
              return <option key={option + index}>{option}</option>
            })}
          </Field>
        </FieldContainer>
      )

    case "radio":
      const optionsArr = elements?.options?.value?.split(",")!
      return (
        <FieldContainer
          role="group"
          aria-labelledby={`${htmlFor}-group`}
          htmlFor={htmlFor}
          label={fieldLabel}
          isFieldRequired={isRequired}
        >
          <div className="fr-form-builder__field--radio-group">
            {optionsArr.map((option, i) => {
              return (
                <label
                  key={`${fieldType}-${htmlFor}-${i}`}
                  htmlFor={htmlFor + i}
                  className="fr-form-builder__radio-item"
                >
                  <Field type="radio" name={nameCheck } value={option} />
                  {option}
                </label>
              )
            })}
          </div>
        </FieldContainer>
      )

    case "hidden":
      return (
        <Field name={htmlFor} type="hidden" value={hidden_field_value?.value} />
      )
    default:
      const fieldPlaceholder = PLACEHOLDER_MESSAGE[htmlFor] ?? ""
      return (
        <FieldContainer
          htmlFor={htmlFor}
          label={fieldLabel}
          isFieldRequired={isRequired}
          className="fr-form-builder__field"
        >
          <Field
            name={nameCheck}
            type="text"
            placeholder={placeholder?.value || fieldPlaceholder}
          />
        </FieldContainer>
      )
  }
}

export const query = graphql`
  fragment FormFieldFragment on kontent_item_form_field {
    id
    __typename
    elements {
      field_type {
        value {
          codename
        }
      }
      form_field {
        value {
          codename
        }
      }
      hidden_field_value {
        value
      }
      label {
        value
      }
      placeholder {
        value
      }
      options {
        value
      }
      required {
        value {
          codename
        }
      }
    }
  }
`

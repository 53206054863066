import React from "react"

export const getPhoneIcon = ( modifier = "", customClassName = "") => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20.336"
      height="22"
      viewBox="0 0 20.336 22"
      className={`${customClassName ? customClassName : `phone-svg${modifier}`}`}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_986"
            data-name="Rectangle 986"
            width="20.336"
            height="22"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Group_2294"
        data-name="Group 2294"
        transform="translate(0 0)"
        opacity="1"
      >
        <g
          id="Group_2293"
          data-name="Group 2293"
          transform="translate(0 0)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Path_14575"
            data-name="Path 14575"
            d="M16.183,22A15.351,15.351,0,0,1,4.254,16.21C-.259,10.506-.567,4.662.535,1.383l.109-.322L.959.935A13.216,13.216,0,0,1,7.4.093l.376.045L7.96.471A10.748,10.748,0,0,1,9.112,7.327L9.066,7.6l-.219.175A15.128,15.128,0,0,1,6.541,9.307a10.532,10.532,0,0,0,1.937,3.08,10.7,10.7,0,0,0,2.649,2.5,15.036,15.036,0,0,1,1.945-1.969l.214-.18.28.009a10.758,10.758,0,0,1,6.5,2.454l.266.219v.344a10.714,10.714,0,0,1-1.675,5.778l-.184.289-.34.045A15.01,15.01,0,0,1,16.183,22M1.83,2.174c-.391,1.455-1.367,6.88,3.556,13.1A13.8,13.8,0,0,0,17.6,20.459a9.236,9.236,0,0,0,1.261-4.347,9.3,9.3,0,0,0-5.063-1.89,13.66,13.66,0,0,0-1.869,2.009l-.32.423-.5-.172a9.459,9.459,0,0,1-3.763-3.161A9.54,9.54,0,0,1,4.969,9.015L4.9,8.489l.477-.233A13.644,13.644,0,0,0,7.707,6.81a9.279,9.279,0,0,0-.861-5.3,11.753,11.753,0,0,0-5.016.663"
            transform="translate(0 0)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  )
}

export const getLoginIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20.003"
      viewBox="0 0 20 20.003"
    >
      <g id="icon" transform="translate(-2.627 -0.001)" opacity="1">
        <path
          id="Path_14710"
          data-name="Path 14710"
          d="M17.467,20H1.489a.891.891,0,0,1,0-1.766h15.2V1.766H1.489A.832.832,0,0,1,.717.885.835.835,0,0,1,1.489,0H17.467a.84.84,0,0,1,.775.885V19.118a.84.84,0,0,1-.775.885"
          transform="translate(4.384 0.001)"
          fill="#fff"
        />
        <path
          id="Path_14711"
          data-name="Path 14711"
          d="M13.872,4.384H.757A.829.829,0,0,1,0,3.5a.825.825,0,0,1,.757-.881H13.872a.827.827,0,0,1,.755.881.83.83,0,0,1-.755.885"
          transform="translate(2.627 6.361)"
          fill="#fff"
        />
        <path
          id="Path_14712"
          data-name="Path 14712"
          d="M4.408,11.025a.883.883,0,0,1-.624-1.509L7.059,6.241,3.808,2.994A.884.884,0,0,1,5.056,1.742L8.931,5.617a.884.884,0,0,1,0,1.252l-3.9,3.9a.88.88,0,0,1-.624.257"
          transform="translate(8.73 3.608)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export const getBurgerIcon = () => {
  return (
    <svg
      id="icon"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="27"
      viewBox="0 0 30 27"
    >
      <rect
        id="Rectangle_812"
        data-name="Rectangle 812"
        width="30"
        height="4.995"
        transform="translate(0)"
        fill="#3089cb"
        className="fr-menu__rect"
      />
      <rect
        id="Rectangle_813"
        data-name="Rectangle 813"
        width="30"
        height="4.995"
        transform="translate(0 11.003)"
        fill="#3089cb"
        className="fr-menu__rect"
      />
      <rect
        id="Rectangle_814"
        data-name="Rectangle 814"
        width="30"
        height="4.995"
        transform="translate(0 22.005)"
        fill="#3089cb"
        className="fr-menu__rect"
      />
    </svg>
  )
}
export const getSideNavCloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.933"
      height="24.93"
      viewBox="0 0 24.933 24.93"
    >
      <path
        id="close-icon"
        d="M288.582,309.783l8.9-8.9-8.906-8.9,3.56-3.56,8.906,8.9,8.906-8.906,3.561,3.561-8.907,8.906,8.9,8.9-3.56,3.559-8.9-8.9-8.9,8.9Z"
        transform="translate(-288.58 -288.413)"
        fill="#004880"
      />
    </svg>
  )
}

import React from "react"

const LeftArrow = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.238"
      height="16.228"
      viewBox="0 0 24.238 16.228"
      className={className}
    >
      <g transform="translate(332.241)">
        <path
          d="M9.475,0V9.475H0v2H11.475V0Z"
          transform="translate(-316.013 8.114) rotate(135)"
          fill="#1a1353"
        />
        <rect
          width="22"
          height="2"
          transform="translate(-330.003 7.1)"
          fill="#1a1353"
        />
      </g>
    </svg>
  )
}

const RightArrow = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.238"
      height="16.228"
      viewBox="0 0 24.238 16.228"
      className={className}
    >
      <path
        d="M9.475,11.475V2H0V0H11.475V11.475Z"
        transform="translate(16.124 0) rotate(45)"
        fill="#1a1353"
      />
      <rect width="22" height="2" transform="translate(0 7.1)" fill="#1a1353" />
    </svg>
  )
}

const LeftChevron = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="17.588"
      viewBox="0 0 10 17.588"
      className={className}
    >
      <path
        d="M8.795,0h0L0,8.74,1.268,10,8.795,2.54,16.32,10l1.268-1.26L8.795,0Z"
        transform="translate(0 17.588) rotate(-90)"
        fill="#1a1353"
      />
    </svg>
  )
}

const RightChevron = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="17.588"
      viewBox="0 0 10 17.588"
      className={className}
    >
      <path
        d="M8.795,10h0L0,1.26,1.268,0,8.795,7.46,16.32,0l1.268,1.26L8.795,10Z"
        transform="translate(0 17.588) rotate(-90)"
        fill="#1a1353"
      />
    </svg>
  )
}

export { LeftArrow, RightArrow, LeftChevron, RightChevron }

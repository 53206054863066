type Breakpoint = Partial<Record<"mobile" | "tablet" | "laptop", {
  quality?: number
  width?: number
}>>

type GetBackgroundImageUrlByBreakpointParams = {
  variableName: string
  imageUrl: string
  breakpoints?: Breakpoint
}

export function getBackgroundImageUrlByBreakpoint({variableName, imageUrl, breakpoints}: GetBackgroundImageUrlByBreakpointParams) {
  const backgroundImageCSSVariable = {} as {[key: string] : string}
  
  backgroundImageCSSVariable[`--${variableName}-mobile`] = 
    `url(${imageUrl}?fm=pjpg&q=${breakpoints?.mobile?.quality ?? 50}&w=${breakpoints?.mobile?.width ?? 1500})` 

  backgroundImageCSSVariable[`--${variableName}-tablet`] = 
    `url(${imageUrl}?fm=pjpg&q=${breakpoints?.tablet?.quality ?? 50}&w=${breakpoints?.tablet?.width ?? 1500})`
  
  backgroundImageCSSVariable[`--${variableName}-laptop`] = 
    `url(${imageUrl}?fm=pjpg&q=${breakpoints?.laptop?.quality ?? 50}&w=${breakpoints?.laptop?.width ?? 1500})`
    
  backgroundImageCSSVariable[`--${variableName}-desktop`] = 
    `url(${imageUrl}?fm=pjpg&q=${breakpoints?.laptop?.quality ?? 50}&w=${breakpoints?.laptop?.width ?? 2560})`

  return backgroundImageCSSVariable
}
import React from "react"
import { Link } from "gatsby"
import { truncString } from "../../../assets/helper/truncString"
import { Button } from "../../button"
import { Image } from "../../image/Image"
import { RiArrowRightSFill } from "react-icons/ri"
import { ImageBuilder } from "../../../_types/AssetsImage"
import { DateBadge } from "./DateBadge"
import { BlogCardContainer } from "./BlogCardContainer"
import "./blog-card.module.scss"
import { useLayout } from "contexts/components/LayoutContext"
import { ReadMoreArrow } from "./ReadMoreArrow"

interface BlogCardProps {
  image?: ImageBuilder
  heading?: string
  description?: string
  slug: string
  hasButton?: boolean
  hasDateTag?: boolean
  hasDateTitle?: boolean
  date?: string
  customOverlayCTAText?: string
}

export const BlogCard = ({
  heading,
  description,
  image,
  slug,
  date,
  hasButton,
  hasDateTag,
  hasDateTitle,
  customOverlayCTAText,
}: BlogCardProps) => {
  const { linkedPagesUrlPathCodex } = useLayout()
  const shortenedDescription = description ? truncString(description, 155) : ""
  const pageSlug = linkedPagesUrlPathCodex[slug] || "404"
  const path = pageSlug.startsWith("/") ? pageSlug : `/${pageSlug}`

  return (
    <BlogCardContainer hasButton={hasButton} slug={path}>
      <div
        className={`fr-blog-card__image-container ${
          !hasButton
            ? "fr-blog-card__image-container--with-linear-gradient"
            : ""
        }`}
      >
        {image && (
          <Image
            image={image}
            className="fr-blog-card__featured-image"
            alt={image.description}
            // width={image.width}
            objectFit="cover"
          />
        )}
        
        {date && hasDateTag && <DateBadge date={date} />}
        <span className="fr-blog-card__cta-tag">
          {!hasButton && (
            <>
              {customOverlayCTAText ? customOverlayCTAText : "Read more"}
              <ReadMoreArrow />
            </>
          )}
        </span>
      </div>
      <div className="fr-blog-card__body">
        {date && hasDateTitle && (
          <time className="fr-blog-card__published-date-text" dateTime={date}>
            {date}
          </time>
        )}
        {hasButton ? (
          <h2 className="fr-blog-card__heading">
            <Link to={path}>{heading}</Link>
          </h2>
        ) : (
          <h2 className="fr-blog-card__heading">{heading}</h2>
        )}
        <p className="fr-blog-card__description">{shortenedDescription}</p>
      </div>
      {hasButton && (
        <Button
          variant="solid-secondary"
          to={path}
          style={{ marginTop: "auto" }}
        >
          Read More
        </Button>
      )}
    </BlogCardContainer>
  )
}

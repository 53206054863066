import React, { useEffect, ReactNode } from "react"
import { Helmet } from "react-helmet"
import { setTrackingCookies } from "../assets/helper/setTrackingCookies"
import { useWindowDimensions } from "../hooks/useWindowDimensions"
import { useLogos } from "../hooks/queries/settings/useLogos"
import { Footer } from "./footer/Footer"
import { SecondaryHeader } from "./secondary-header/SecondaryHeader"
import { Header } from "./main-header/Header"
import { SideNav } from "./side-nav/SideNav"
import { useNavigationItems } from "../hooks/useNavigationItems"
import { useGeneralSettings } from "../hooks/useGeneralSettings"
import { LayoutContext } from "contexts/components/LayoutContext"
import { MainNavigation } from "./main-navigation/MainNavigation"
import scrollToElement from 'scroll-to-element';
import { appendScript, appendInlineScript } from "../assets/helper/appendScript"

interface LayoutProps {
  children: ReactNode
  pathName: string
  location: string
  hash?: string
  linkedPagesUrlPathCodex: Record<string, string>
  mobilePreload?: string
  parentSlug?: string
}

export const Layout = ({
  children,
  location,
  pathName,
  hash,
  mobilePreload,
  linkedPagesUrlPathCodex,
  parentSlug
}: LayoutProps) => {
  const { size, height } = useWindowDimensions()
  const slug = location.startsWith("/") && location !== "/" ? location.substring(1) : "home"

  let scrollClass = ""

  if (height > 45) scrollClass = "page-content--scroll-version"

  const { customCSSUrl, scripts, domainVerification } = useGeneralSettings()

  const {
    headerNavigation,
    footerNavigation,
    creditsNavigation,
    sideNavigation,
  } = useNavigationItems()

  const { footerLogo, headerLogo, sideNavLogo } = useLogos()

  useEffect(() => {
    setTrackingCookies()
    if (window) {
      window.addEventListener("message", event => {
        if (
          event.data.type === "hsFormCallback" &&
          event.data.eventName === "onFormSubmit"
        ) {
          console.log("Form Submitted!", event)
          window.localStorage.setItem("leadSaved", "True")
        }
      })
    }
    if (scripts) {
      scripts?.modular_content.map((script, index) => {
        script.elements.src_value?.value !== "" && appendScript({
          id: `globalScript-${index + 1}`,
          scriptToAppend: script.elements.src_value?.value || "",
          isAsync: true
        });

        script.elements.script?.value !== "" && appendInlineScript({
          id: `globalScriptInline-${index + 1}`,
          scriptToAppend: script.elements.script?.value || "",
          isAsync: false,
        })
      })
    }
  }, [])

  useEffect(() => {
    if (!hash) return
    let mounted = true

    setTimeout(() => {
      if (mounted) {
        scrollToElement(hash, {
          duration: 1000,
          offset: -200
        })
      }
    }, 200)

    return () => {
      mounted = false
    }
  }, [hash])

  return (
    <LayoutContext.Provider
      value={{
        linkedPagesUrlPathCodex,
        breadcrumbLocation: pathName,
        slug: location,
        parentSlug
      }}
    >
      <div className={`wrapper wrapper--${slug === "/" ? "home" : slug}`}>
        <Helmet meta={domainVerification && [{ name: "google-site-verification", content: domainVerification }]}>
          {customCSSUrl && <link rel="stylesheet" href={customCSSUrl} />}
          {mobilePreload && size < 768 && (
            <link rel="preload" as="image" href={mobilePreload} />
          )}
        </Helmet>

        <SecondaryHeader />

        <Header logo={headerLogo}>
          {headerNavigation.length > 0 && (
            <nav className={"fr-header__nav"}>
              {headerNavigation.map((navItem, i) => {
                return (
                  <MainNavigation
                    key={navItem.id}
                    navItem={navItem}
                    index={i}
                    navCount={headerNavigation.length}
                  />
                )
              })}
            </nav>
          )}
        </Header>

        <main className={`page-content ${scrollClass}`}>{children}</main>

        {sideNavigation.length > 0 && (
          <SideNav
            navItems={sideNavigation}
            logo={sideNavLogo}
          />
        )}

        <Footer
          logo={footerLogo}
          creditsNavigation={creditsNavigation}
          footerNavigation={footerNavigation}
        />
      </div>
    </LayoutContext.Provider>
  )
}

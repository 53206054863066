import { ErrorMessage } from 'formik';
import React, { HTMLAttributes, ReactNode } from 'react'
import { AriaRole } from 'react';
import { useFormBuilder } from '../../contexts/components/FormBuilderContex';

interface FieldContainer extends HTMLAttributes<HTMLDivElement> {
  htmlFor: string
  label: string
  isFieldRequired: string
  children: ReactNode
  role?: AriaRole
}

export const FieldContainer = ({children, htmlFor, label, isFieldRequired, role, ...rest}: FieldContainer) => {
  const {errors, touched} = useFormBuilder()

  const fieldValidationErrorClassName = touched[htmlFor] && errors[htmlFor] ? "fr-form-builder__field--validation-error" : ""
  const fieldValidationSuccessClassName = touched[htmlFor] && !errors[htmlFor] ? "fr-form-builder__field--validation-success" : ""

  return (
    <div {...rest} className={`${rest.className} ${fieldValidationSuccessClassName} ${fieldValidationErrorClassName}`}>
      <label htmlFor={(role && role === 'group') ? `${htmlFor}-group` : htmlFor}>
        {label}:{" "}
        <span className="required">{isFieldRequired === "yes" ? "*" : ""}</span>
      </label>
        {children}
      <span className={`required fr-form-builder__form-validation-error-message`}>
        <ErrorMessage name={htmlFor}>{(errorMessage: string) => errorMessage === "Required" ? "This field is required" : `${errorMessage}`}</ErrorMessage>
      </span>
    </div>
  );
}
import React from "react"
import { Link } from "gatsby"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { Credits } from "./Credits"
import { SocialIcons } from "../social-icons/SocialIcons"
import { FooterLocation } from "./FooterLocation"
import "./footer.module.scss"
import { NavigationLinksOutput } from "contexts/settings/Navigation/navigation-types"
import { ImageBuilder } from "_types/AssetsImage"

interface FooterProps {
  logo: ImageBuilder
  creditsNavigation: NavigationLinksOutput[]
  footerNavigation: NavigationLinksOutput[]
  isLandingPage?: boolean
  customTrackingNumber?: string
}

export const Footer = ({
  logo,
  creditsNavigation,
  isLandingPage = false,
  customTrackingNumber,
}: FooterProps) => {
  const {
    customCTAText,
    socialMedia,
    trackingNumbers,
    formattedOffices,
    usePhoneNumberNotTrackingNumber,
  } = useGeneralSettings()
  const trackingPhoneNumber =
    trackingNumbers.length > 0 ? trackingNumbers[0]?.trackingNumber : ""
  const singlePhoneNumber =
    trackingNumbers.length > 0 ? trackingNumbers[0]?.phoneNumber : ""

  const contactNumber = usePhoneNumberNotTrackingNumber
    ? singlePhoneNumber
    : trackingPhoneNumber

  const isLessThanOrEqualTwoOffices = formattedOffices.length <= 2
  const isMoreThanTwoModifier = isLessThanOrEqualTwoOffices
    ? ""
    : "--more-than-2"

  return (
    <footer className="fr-footer">
      <div className={`fr-container fr-footer__inner${isMoreThanTwoModifier}`}>
        <Link to="/" className="base-logo">
          <img
            src={`${logo.url}?q=60&w=200`}
            alt={logo.description}
            loading="lazy"
          />
        </Link>
        <p className="fr-footer__cta">{customCTAText}</p>
        {contactNumber && (
          <a
            href={`tel: ${
              customTrackingNumber && isLandingPage
                ? customTrackingNumber
                : contactNumber
            }`}
            className="fr-footer__phone"
          >
            {customTrackingNumber && isLandingPage
              ? customTrackingNumber
              : contactNumber}
          </a>
        )}

        {!isLandingPage && socialMedia.length > 0 && (
          <div className="fr-footer__social-container">
            {socialMedia.map(
              ({ linkUrl, mediaOutlet, customIcon, customOutletName, id }) => {
                return (
                  <SocialIcons
                    key={id}
                    href={linkUrl}
                    socialMediaOutlet={mediaOutlet}
                    customOutletName={customOutletName}
                    customIcon={customIcon}
                    whereAmI="--footer"
                  />
                )
              }
            )}
          </div>
        )}
        {formattedOffices.length > 0 && (
          <div
            className={`fr-footer__address-container${isMoreThanTwoModifier}`}
          >
            {formattedOffices.map(office => (
              <FooterLocation
                office={office}
                addressModifier={isMoreThanTwoModifier}
                key={office.id}
              />
            ))}
          </div>
        )}
      </div>
      <div className="fr-footer__credits-container">
        <Credits creditsNavigation={creditsNavigation} />
      </div>
    </footer>
  )
}

import React from "react"
import { AnchorLink } from "components/anchor-link/AnchorLink"
import { ExternalLink } from "components/external-link/ExternalLink"
import { useLayout } from "contexts/components/LayoutContext"
import { ButtonBuilder } from "_types/Button"
import { Button, ButtonProps } from "."
import { useSideNav } from "hooks/useSideNav"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { getPhoneIcon } from "assets/helper/icons"
import { MultipleChoice } from "_types/MultipleChoice"
interface ButtonParserProps {
  buttons: ButtonBuilder[]
  primaryColorVariant?: ButtonProps["variant"]
  secondaryColorVariant?: ButtonProps["variant"]
  hasFallbackColorVariant?: boolean
  fallbackColorVariant?: ButtonProps["variant"]
  extraClassNames?: string
}

// Use the fallbackColorVariant to customize the default color variant of the button or link when the buttons list has only one item
// By default, the fallbackColorVariant is the same as the secondaryColorVariant
export const ButtonParser = ({
  buttons,
  primaryColorVariant = "solid-primary",
  secondaryColorVariant = "outline-primary",
  hasFallbackColorVariant = false,
  fallbackColorVariant,
  extraClassNames = "",
}: ButtonParserProps) => {
  const defaultFallbackColorVariant =
    fallbackColorVariant || secondaryColorVariant
  const { isNavOpen, handleCloseNav } = useSideNav()
  const { linkedPagesUrlPathCodex } = useLayout()
  const { trackingNumbers, usePhoneNumberNotTrackingNumber } =
    useGeneralSettings()

  const MainOfficeTrackingPhoneNumber =
    trackingNumbers.length > 0 ? trackingNumbers[0]?.trackingNumber : ""
  const MainOfficeSinglePhoneNumber =
    trackingNumbers.length > 0 ? trackingNumbers[0]?.phoneNumber : ""
  const MainOfficeNumber = usePhoneNumberNotTrackingNumber
    ? MainOfficeTrackingPhoneNumber
    : MainOfficeSinglePhoneNumber

  const getLinksClassName = (index: number) => {
    let linkClassName = ""

    if (hasFallbackColorVariant) {
      linkClassName =
        buttons.length > 1
          ? index === 0
            ? `button--${primaryColorVariant} ${extraClassNames}`
            : `button--${secondaryColorVariant} ${extraClassNames}`
          : `button--${defaultFallbackColorVariant} ${extraClassNames}`
    } else {
      linkClassName =
        index === 0
          ? `button--${primaryColorVariant} ${extraClassNames}`
          : `button--${secondaryColorVariant} ${extraClassNames}`
    }
    return linkClassName
  }

  const getButtonVariant = (index: number) => {
    let variant: ButtonProps["variant"]

    if (hasFallbackColorVariant) {
      variant =
        buttons.length > 1
          ? index === 0
            ? primaryColorVariant
            : secondaryColorVariant
          : defaultFallbackColorVariant
    } else {
      variant = index === 0 ? primaryColorVariant : secondaryColorVariant
    }

    return variant
  }

  return (
    <>
      {buttons.map(({ id, elements, system }, i) => {
        const componentType = system.type
        const {
          button_text,
          linked_page,
          external_link,
          anchor_reference,
          custom_anchor_reference,
          use_main_office_call_tracking_number,
          custom_call_tracking_number,
          cta_override,
        } = elements
        const isCTAOverride = cta_override?.value[0]?.codename === "yes"
        if (componentType === "anchor_button") {
          const { slug: layoutSlug } = useLayout()
          const slugValue = linked_page?.value[0]?.elements?.slug?.value!
          const slug = linkedPagesUrlPathCodex[slugValue]
          const pageSlug = slug || linkedPagesUrlPathCodex[layoutSlug]
          const anchorReference =
            custom_anchor_reference?.value ? custom_anchor_reference.value : anchor_reference?.value[0]?.name ? anchor_reference?.value[0]?.name : 'form-banner'
          const anchorLinkSlug = pageSlug?.startsWith("/")
            ? `${pageSlug}#${anchorReference}`
            : `/${pageSlug}#${anchorReference}`

          if (!slug || slug === layoutSlug) {
            return (
              <AnchorLink
                key={id}
                to={anchorLinkSlug}
                className={getLinksClassName(i)}
                onAnchorLinkClick={() => {
                  if (isNavOpen) {
                    handleCloseNav()
                  }
                }}
              >
                {button_text?.value}
              </AnchorLink>
            )
          }
          // Layout has a useEffect to handle initial load of pages with a hash. E.g: /commercial-pest-control#bpco-container
          return (
            <Button
              key={id}
              to={anchorLinkSlug}
              variant={getButtonVariant(i)}
              extraClassNames={extraClassNames}
            >
              {button_text?.value}
            </Button>
          )
        }

        if (external_link?.value) {
          return (
            <ExternalLink
              key={id}
              href={external_link?.value}
              className={getLinksClassName(i)}
              onClick={() => {
                if (isNavOpen) {
                  handleCloseNav()
                }
              }}
            >
              {button_text.value}
            </ExternalLink>
          )
        }

        const useMainOfficeTrackingNumber =
          use_main_office_call_tracking_number?.value[0]?.codename === "yes"
            ? true
            : false
        const customPhoneNumber = custom_call_tracking_number?.value ?? ""
        const hasPhoneNumber = !!customPhoneNumber

        if (useMainOfficeTrackingNumber || hasPhoneNumber) {
          const contactNumber = useMainOfficeTrackingNumber
            ? MainOfficeNumber
            : customPhoneNumber
          return (
            <a
              key={id}
              href={`tel: ${contactNumber}`}
              className={`${getLinksClassName(i)} u-phone-link`}
              onClick={() => {
                if (isNavOpen) {
                  handleCloseNav()
                }
              }}
            >
              {getPhoneIcon("", "left-icon")}
              {button_text.value ? button_text.value : contactNumber}
            </a>
          )
        }

        const slugValue = linked_page?.value[0]?.elements?.slug?.value || "404"
        const slug = linkedPagesUrlPathCodex[slugValue]

        return (
          <Button
            key={id}
            to={slug.startsWith("/") ? slug : `/${slug}`}
            variant={getButtonVariant(i)}
            extraClassNames={extraClassNames}
            ctaOverride={isCTAOverride}
          >
            {button_text?.value}
          </Button>
        )
      })}
    </>
  )
}

import { useContext } from "react"
import { SideNavContext, SideNavContextData } from "../contexts/SideNavContext"

export const useSideNav = (): SideNavContextData => {
  const context = useContext(SideNavContext)

  if (!context) {
    throw new Error("useSideNav must be used within SideNavProvider")
  }

  return context
}

import { InitialFormValues, ValidationObject } from "../formBuilder-types"
import { buildValidationSchema } from "./buildValidationSchema"

interface GetInitialFormValuesParams {
  fields: any[]
  validationObject: ValidationObject
  initialFormValues: InitialFormValues
  fullName: string
  email: string
}

export const getInitialFormValues = ({fields, validationObject, initialFormValues, fullName, email}: GetInitialFormValuesParams) => {
  fields.forEach((field: any) => {
    const label = field.elements?.label!
    const form_field = field?.elements?.form_field?.value ? field.elements.form_field.value[0]?.codename! : ""
    const required = field?.elements?.required?.value ? field.elements.required.value[0]?.codename! : ""
    const hiddenFieldValue = field?.elements?.hidden_field_value?.value!
    const customValue = label?.value?.replace(/\s+/g, "")!
    form_field === "custom"
        ? buildValidationSchema({field: customValue, required, validationObject})
        : buildValidationSchema({field: form_field, required, validationObject})

    switch (form_field) {
      case "full_name":
        initialFormValues[form_field] = fullName
        break
      case "email":
        initialFormValues[form_field] = email
        break
      case "custom":
        initialFormValues[customValue] = hiddenFieldValue ?? ""
        break
      default:
        initialFormValues[form_field] = hiddenFieldValue ?? ""
        break
    }
  })
}
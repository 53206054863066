import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { FiChevronDown, FiChevronUp } from "react-icons/fi"
import { useSideNav } from "../../hooks/useSideNav"
import { ExternalLink } from "../external-link/ExternalLink"
import "./side-nav.module.scss"
import { ChildPagesFormatted } from "../../contexts/settings/Navigation/navigation-types"
import { useLayout } from "contexts/components/LayoutContext"
import { useIntegrationsSettings } from "hooks/useIntegrationsSettings"

interface SubNavProps {
  id: string
  linkText: string
  parentSlug: string
  childPages: ChildPagesFormatted[]
}

export const SubNav = ({
  id,
  parentSlug,
  childPages,
  linkText,
}: SubNavProps) => {
  const { breadcrumbLocation, linkedPagesUrlPathCodex } = useLayout()
  const { hasFieldroutesReviews } = useIntegrationsSettings()

  const { handleCloseNav, isNavOpen, activeSubNavId, handleToggleSubNav } =
    useSideNav()
  const isOpen = activeSubNavId === id
  // const [isOpen, setIsOpen] = useState(activeSubNavId === id)
  const [isLocation, setLocation] = useState(false)

  const handleSubNav = (activeItemId: string) => {
    handleToggleSubNav(activeItemId)
    // setIsOpen(state => !state)
  }

  const checkChildElements = (childPages: ChildPagesFormatted[]) => {
    const HOMEPAGE_SLUG = "/"
    childPages.forEach(({ slug }) => {
      const pageSlug = slug.startsWith("/") ? slug : `/${slug}`
      const parentPageSlug = parentSlug.startsWith("/")
        ? parentSlug
        : `/${parentSlug}`
      const isCurrentChildPageLocation =
        pageSlug === breadcrumbLocation && breadcrumbLocation !== HOMEPAGE_SLUG
      const isCurrentParentLocation = parentPageSlug === breadcrumbLocation
      const isParentNotHomepage = parentPageSlug !== HOMEPAGE_SLUG
      const isWithinParentAndNotHomepage =
        isCurrentParentLocation && isParentNotHomepage
      if (isCurrentChildPageLocation || isWithinParentAndNotHomepage) {
        // setIsOpen(true)
        handleToggleSubNav(id) 
        setLocation(true)
        return
      }
    })
  }

  useEffect(() => {
    let mounted = true
    if (!isNavOpen) {
      setTimeout(() => {
        if (mounted) {
          if (isOpen && !isLocation) {
            // setIsOpen(false)
            handleToggleSubNav("")
          } else if (!isOpen && isLocation) {
            handleToggleSubNav(id)
            // setIsOpen(true)
          }
        }
      }, 300)
      return
    }

    if (mounted && !isOpen && !isLocation) checkChildElements(childPages)
    return () => {
      mounted = false
    }
  }, [isOpen, isLocation, isNavOpen, id])

  const getChildElements = (childPages: ChildPagesFormatted[]) => {
    return (
      <ul
        className={
          isOpen
            ? "side-nav__sub-nav-list"
            : "side-nav__sub-nav-list side-nav__sub-nav-list--sub-nav-closed"
        }
      >
        {childPages.map(({ id, slug, linkText, svg_icon, externalLink }) => {
          // if pestroutes reviews are off we will not return that menu item
          if (!hasFieldroutesReviews && slug === "reviews") return null
          const pageSlug = linkedPagesUrlPathCodex[slug] || "404"

          if (externalLink) {
            return (
              <li className="side-nav__sub-nav-li" key={id}>
                <ExternalLink
                  className="side-nav__sub-nav-link"
                  href={externalLink}
                  key={id}
                >
                  {linkText}
                </ExternalLink>
              </li>
            )
          } else {
            return (
              <li className="side-nav__sub-nav-li" key={id}>
                <Link
                  onClick={handleCloseNav}
                  to={
                    pageSlug?.startsWith("/") ? `${pageSlug}` : `/${pageSlug}`
                  }
                  activeClassName="side-nav__sub-nav-link--active"
                  className="side-nav__sub-nav-link"
                >
                  {linkText}
                </Link>
              </li>
            )
          }
        })}
      </ul>
    )
  }

  if (parentSlug) {
    const pageSlug = linkedPagesUrlPathCodex[parentSlug] || "404"
    return (
      <li
        className={
          isOpen || isLocation
            ? " side-nav__link side-nav__link-active"
            : "side-nav__link"
        }
      >
        <div className="side-nav__sub-nav-parent">
          <Link
            to={pageSlug?.startsWith("/") ? `${pageSlug}` : `/${pageSlug}`}
            onClick={handleCloseNav}
          >
            {linkText}
          </Link>
          <button
            onClick={() => handleSubNav(id)}
            className="side-nav__chevron"
            aria-label="Drop Down Button"
          >
            {isOpen && <FiChevronUp />}
            {!isOpen && <FiChevronDown />}
          </button>
        </div>
        {getChildElements(childPages)}
      </li>
    )
  } else {
    return (
      <li
        className={
          isOpen || isLocation
            ? "side-nav__link side-nav__link-active"
            : "side-nav__link"
        }
      >
        <button
          className="side-nav__sub-nav-parent side-nav__sub-nav-parent--button"
          onClick={() => handleSubNav(id)}
          aria-label="Drop Down Button"
        >
          {linkText}
          <span className="side-nav__chevron">
            {isOpen && <FiChevronUp />}
            {!isOpen && <FiChevronDown />}
          </span>
        </button>
        {getChildElements(childPages)}
      </li>
    )
  }
}

import React, { ReactNode, useRef } from "react"
import { IoCloseSharp } from "react-icons/io5"
import { FocusOn } from "react-focus-on"
import { navigate } from "@reach/router"
import { Button } from "../button"
import { LeftChevron } from "../blog/InternalPagination/Arrows"
import "./modal.module.scss"

interface ModalProps {
  children: ReactNode
  variant: "solid-single-column" | "mix-2-columns"
  onClose?: () => void
  isGated?: boolean
  bgColor?: string
}

const VARIANT_CLASSNAME = {
  "solid-single-column": "fr-modal-container__content--solid-single-column",
  "mix-2-columns": "fr-modal-container__content--mix-2-columns",
}

export const Modal = ({
  children,
  onClose,
  variant,
  isGated = false,
  bgColor,
}: ModalProps) => {
  const variantClassName = VARIANT_CLASSNAME[variant]

  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleGoBack = () => {
    if (typeof history !== "undefined") {
      navigate(-1)
    } else {
      navigate("/")
    }
  }
  return (
    <div role="dialog" className="fr-modal-container">
      {!isGated && (
        <button
          className="fr-modal-container__close-button"
          onClick={onClose}
          ref={buttonRef}
        >
          <span>Close</span>
          <IoCloseSharp />
        </button>
      )}

      <FocusOn
        shards={[buttonRef]}
        onClickOutside={!isGated ? onClose : undefined}
        onEscapeKey={!isGated ? onClose : undefined}
      >
        <div
          className={`fr-modal-container__content ${variantClassName}`}
          style={{ bgColor } && { backgroundColor: bgColor }}
        >
          {isGated && (
            <Button
              variant="flashy-icon"
              extraClassNames="fr-modal-container__back-button"
              onClick={handleGoBack}
            >
              <LeftChevron />
              Back
            </Button>
          )}
          {children}
        </div>
      </FocusOn>
      <div className="fr-modal-container__overlay" aria-label="Modal overlay" />
    </div>
  )
}

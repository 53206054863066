import React from "react"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import "./testimonial.module.scss"
import { ImageBuilder } from "../../_types/AssetsImage"
import { Image } from "../image/Image"

interface TestimonialProps {
  review: string
  rating: string
  reviewerName: string
  reviewerLocation: string
  hasFieldroutesReviews?: boolean
  avatar?: ImageBuilder
}

export const Testimonial = ({
  review,
  rating,
  reviewerName,
  reviewerLocation,
  hasFieldroutesReviews = false,
  avatar,
}: TestimonialProps) => {
  return (
    <div
      className={`fr-testimonial__body ${
        hasFieldroutesReviews
          ? "fr-testimonial__body--fadeIn"
          : "fr-testimonial__body"
      }`}
    >
      {avatar && (
        <Image
          image={avatar}
          alt={avatar.description}
          className="fr-testimonial__avatar fr-testimonial__avatar--hide-on-mobile"
          width={avatar.width}
          height={avatar.height}
        />
      )}
      <div
        className={
          avatar
            ? `fr-testimonial__body-content`
            : `fr-testimonial__body-content fr-testimonial__body-content--no-avatar`
        }
      >
        {hasFieldroutesReviews && <p>"{review}"</p>}
        {!hasFieldroutesReviews && (
          <div className="fr-testimonial__body-content--paragraph">
            <RichTextElement value={review} />
          </div>
        )}
        <div className="fr-testimonial-reviewer">
          {avatar && (
            <Image
              image={avatar}
              alt={avatar.description}
              className="fr-testimonial__avatar fr-testimonial__avatar--hide-on-laptop"
              width={avatar.width}
              height={avatar.height}
            />
          )}
          {rating && (
            <span className="fr-testimonial-reviewer__rating-stars">
              {rating}
            </span>
          )}
          <div className="fr-testimonial-reviewer__info">
            <strong>{reviewerName}</strong>
            {reviewerLocation && <span>{reviewerLocation}</span>}
          </div>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import { graphql } from "gatsby"
import {
  FaFacebookF,
  FaGoogle,
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaYelp,
  FaBloggerB,
} from "react-icons/fa"
import "./social.module.scss"

interface SocialIconsProps {
  href: string
  socialMediaOutlet: string
  customOutletName?: string
  customIcon?: string
  whereAmI?: string
}

export const SocialIcons = ({
  href,
  socialMediaOutlet,
  customOutletName = "",
  customIcon = "",
  whereAmI = "",
}: SocialIconsProps) => {
  const getSocials = (mediaOutlet: string) => {
    switch (mediaOutlet) {
      case "instagram":
        return (
          <a
            href={href}
            className={`social-icon${whereAmI}`}
            aria-label="instagram button"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram />
          </a>
        )
      case "facebook":
        return (
          <a
            href={href}
            className={`social-icon${whereAmI}`}
            aria-label="facebook button"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookF />
          </a>
        )
      case "google":
        return (
          <a
            href={href}
            className={`social-icon${whereAmI}`}
            aria-label="google button"
            target="_blank"
            rel="noreferrer"
          >
            <FaGoogle />
          </a>
        )
      case "linked_in":
        return (
          <a
            href={href}
            className={`social-icon${whereAmI}`}
            aria-label="linkedin button"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedinIn />
          </a>
        )
      case "twitter":
        return (
          <a
            href={href}
            className={`social-icon${whereAmI}`}
            aria-label="twitter button"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter />
          </a>
        )
      case "yelp":
        return (
          <a
            href={href}
            className={`social-icon${whereAmI}`}
            aria-label="yelp button"
            target="_blank"
            rel="noreferrer"
          >
            <FaYelp />
          </a>
        )
      case "youtube":
        return (
          <a
            href={href}
            className={`social-icon${whereAmI}`}
            aria-label="youtube button"
            target="_blank"
            rel="noreferrer"
          >
            <FaYoutube />
          </a>
        )
      case "blogger":
        return (
          <a
            href={href}
            className={`social-icon${whereAmI}`}
            aria-label="blogger button"
            target="_blank"
            rel="noreferrer"
          >
            <FaBloggerB />
          </a>
        )

      default:
        return (
          <a
            href={href}
            className={`social-icon${whereAmI}`}
            aria-label={`${customOutletName} button`}
            target="_blank"
            rel="noreferrer"
          >
            <span
              contentEditable="false"
              dangerouslySetInnerHTML={{ __html: customIcon }}
            />
          </a>
        )
    }
  }

  return <>{getSocials(socialMediaOutlet)}</>
}

export const query = graphql`
  fragment SocialMediaLinkFragment on kontent_item_social_media_link {
    id
    elements {
      link {
        value
      }
      custom_outlet {
        value
      }
      custom_icon {
        value
      }
      social_media_outlet {
        value {
          codename
        }
      }
    }
  }
`

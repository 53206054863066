import React from "react"
import { graphql, Link } from "gatsby"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import "../content-blocks/content-block.module.scss"
import { RichTextContent } from "../../_types/RichText"
import { ResolvedUrlLink, DomNode } from "./content-types"
import "./content.module.scss"
import { useLayout } from "contexts/components/LayoutContext"

type ContentProps = {
  content: RichTextContent
  styleListsAsGrid?: boolean
}

export const Content = ({ content, styleListsAsGrid }: ContentProps) => {
  const { linkedPagesUrlPathCodex } = useLayout()

  const listsAsGrid = styleListsAsGrid ? " fr-content-list" : ""
  let maxListColums = "";
  const contentFormatted = content.value.includes("iframe")
    ? content.value.replace(/&lt;/g, "<").replace(/&gt;/g, ">")
    : content.value
  
  if(styleListsAsGrid){
    let itemCount = contentFormatted.split('<li>').length - 1 
    if(itemCount && itemCount <= 4)
      maxListColums = 'fr-content-list--max-'+itemCount;
    // else if(itemCount>4 && itemCount <=6) //especial case item count low
    //   maxListColums = 'fr-content-list--max-3' //3 column max balanced distribution
  }  
  if (content?.links && content.links?.length > 0) {
    return (
      <div className={`fr-content-container ${listsAsGrid} ${maxListColums}`}>
        <RichTextElement
          value={contentFormatted}
          links={content.links}
          resolveLink={(link: ResolvedUrlLink, domNode: DomNode) => {
            if (link) {
              const slug = linkedPagesUrlPathCodex[link.url_slug]
              const finalSlug = !!slug ? `/${slug}` : "/404"
              if (domNode.children[0] && domNode.children[0].children) {
                return (
                  <Link to={finalSlug}>
                    {domNode.children[0].children[0].data}
                  </Link>
                )
              } else if (domNode.children[0] && domNode.children[0].data) {
                return <Link to={finalSlug}>{domNode.children[0].data}</Link>
              }
            }
            return ""
          }}
        />
      </div>
    )
  }

  return (
    <div className={`fr-content-container ${listsAsGrid} ${maxListColums}`}>
      <RichTextElement value={contentFormatted} />
    </div>
  )
}

export const query = graphql`
  fragment RichTextContentFragment on kontent_item_rich_text_element_value {
    images {
      description
      height
      image_id
      url
      width
    }
    links {
      link_id
      url_slug
    }
    value
  }
`

import { useContext } from "react"
import { GeneralSettingsContext } from "../contexts/settings/GeneralSettingsContext/GeneralSettingsContext"
import { GeneralSettingsContextData } from "../contexts/settings/GeneralSettingsContext/generalSettings-types"

export const useGeneralSettings = (): GeneralSettingsContextData => {
  const context = useContext(GeneralSettingsContext)

  if (!context) {
    throw new Error(
      "useGeneralSettings must be used within GeneralSettingsProvider"
    )
  }

  return context
}

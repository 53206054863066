import React, { HTMLAttributes } from 'react'
import "./skeleton.modules.scss"

interface SkeletonElementProps extends Omit<HTMLAttributes<HTMLDivElement>, "className"> {
  type: "heading" | "text" | "avatar" | "thumbnail"
  modifier?: "full" | "half" | "quarter" | "dime" | undefined
  theme?: "light" | "dark"
  additionalClassNames?: string
}

export const SkeletonElement = ({type, modifier = undefined, theme = "light", additionalClassNames, ...rest}: SkeletonElementProps) => {
  const classNames = modifier ? `fr-skeleton fr-skeleton--${theme} fr-skeleton__${type} fr-skeleton--${modifier}` : `fr-skeleton fr-skeleton--${theme} fr-skeleton__${type}`
  const classNamesWithAdditionalClassNames = additionalClassNames ? `${classNames} ${additionalClassNames}` : classNames

  return (
    <div className={classNamesWithAdditionalClassNames} {...rest}></div>
  )
}
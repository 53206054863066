import React from "react"
import { getBurgerIcon } from "../../assets/helper/icons"
import { useSideNav } from "../../hooks/useSideNav"
import "./header.module.scss"

const HamburgerMenu = () => {
  const { handleOpenNav } = useSideNav()

  return (
    <button
      onClick={handleOpenNav}
      className="fr-header__burger"
      aria-label="open side menu"
    >
      {getBurgerIcon()}
    </button>
  )
}

export default HamburgerMenu

import React from "react"
import { graphql } from "gatsby"
import { RichTextContent } from "_types/RichText"
import { Fields } from "./formBuilder-types"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { FieldsParser } from "./FieldsParser"

interface FormFieldGroupProps {
  sectionDetails?: RichTextContent
  fields: Fields
}
export const FormFieldGroup = ({
  sectionDetails,
  fields,
}: FormFieldGroupProps) => {
  const getInputFieldsCount = () => {
    let count = 0
    fields.modular_content.map(({ elements }) => {
      const fieldType = elements.field_type.value[0].codename

      if (
        fieldType === "text" ||
        fieldType === "radio" ||
        fieldType === "date" ||
        fieldType === "select"
      ) {
        count++
      }
    })
    return count
  }
  const inputFieldsCount = getInputFieldsCount()

  return (
    <div className="field-group">
      {sectionDetails && sectionDetails.value.toString() !== "<p><br></p>" && (
        <RichTextElement value={sectionDetails.value} />
      )}
      <div
        className="field-group__fields"
        style={
          inputFieldsCount <= 3
            ? {
                gridTemplateColumns: `repeat(${inputFieldsCount}, 1fr)`,
              }
            : { gridTemplateColumns: `repeat(3,1fr)` }
        }
      >
        {fields.modular_content.map(({ id, elements }) => {
          const fieldType = elements?.field_type?.value
            ? elements.field_type.value[0]?.codename!
            : ""
          return (
            <FieldsParser key={id} fieldType={fieldType} elements={elements} />
          )
        })}
      </div>
    </div>
  )
}

export const query = graphql`
  fragment FormFieldGroupFragment on kontent_item_form_field_group {
    id
    __typename
    elements {
      section_details {
        value
      }
      fields {
        modular_content {
          ...FormFieldFragment
        }
      }
    }
  }
`

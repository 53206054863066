import React, { useCallback, useState } from "react"
import { SecondaryHeaderContext } from "contexts/components/SecondaryHeaderContext"
import { SecondaryHeaderLaptop } from "./SecondaryHeaderLaptop"
import { SecondaryHeaderMobile } from "./SecondaryHeaderMobile"
import "./secondary-header.module.scss"

export const SecondaryHeader = () => {
  const [isModalOpen, setModalOpen] = useState(false)
  const handleOpenModal = useCallback(() => {
    setModalOpen(true)
  }, [])
  const handleCloseModal = useCallback(() => {
    setModalOpen(false)
  }, [])
  return (
    <section className="secondary-header">
      <SecondaryHeaderContext.Provider
        value={{
          handleCloseModal,
          handleOpenModal,
          isModalOpen,
        }}
      >
        <SecondaryHeaderLaptop />
        <SecondaryHeaderMobile />
      </SecondaryHeaderContext.Provider>
    </section>
  )
}

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Button } from "../../button"
import { BlogCard } from "../BlogCard/BlogCard"
import {
  BlogPostEdge,
  BlogPosts,
  GraphQLBlogPostsData,
} from "./blog-banner-types"
import "./blog-banner.module.scss"

interface BlogBannerProps {
  heading: string
  subHeading: string
  parentSlug: string
  relatedArticles: BlogPostEdge[]
}

export const BlogBanner = ({
  heading,
  subHeading,
  relatedArticles,
  parentSlug = "",
}: BlogBannerProps) => {
  const { posts } = useStaticQuery<GraphQLBlogPostsData>(
    getBlogBannerFooterPosts
  )

  const getRelatedArticles = (relatedArticles: BlogPostEdge[]) => {
    if (relatedArticles.length > 0) {
      return relatedArticles.map(({ node }: any) => {
        const { id, elements } = node

        const {
          featured_image,
          featured_text,
          heading,
          published_date,
          slug,
          seo_metadata__overlay_cta_text,
        } = elements
        return (
          <BlogCard
            key={id}
            heading={heading?.value}
            slug={slug?.value}
            description={featured_text?.value}
            image={featured_image?.value[0]}
            customOverlayCTAText={seo_metadata__overlay_cta_text?.value}
            date={published_date?.value}
            hasDateTag
          />
        )
      })
    }
  }

  const getBlogArticles = (posts: BlogPosts) => {
    if (posts?.edges?.length === 0) return null
    return posts?.edges?.map(({ node }) => {
      const { id, elements } = node

      const {
        featured_image,
        featured_text,
        heading,
        published_date,
        slug,
        seo_metadata__overlay_cta_text,
      } = elements

      return (
        <BlogCard
          key={id}
          heading={heading?.value}
          slug={slug?.value}
          description={featured_text?.value}
          image={featured_image?.value[0]}
          customOverlayCTAText={seo_metadata__overlay_cta_text?.value}
          date={published_date?.value}
          hasDateTag
        />
      )
    })
  }

  return (
    <section className="fr-blog-banner alternate-background-color">
      <div className="fr-container fr-blog-banner__wrapper">
        {heading && (
          <div className="fr-blog-banner__heading-container">
            <h2 className="fr-blog-banner__heading">
              {relatedArticles.length > 0 ? "Recommended" : heading}
            </h2>

            {relatedArticles.length === 0 && subHeading && (
              <h3>{subHeading}</h3>
            )}
          </div>
        )}

        <div
          className={`fr-blog-banner__blog-list${
            relatedArticles.length > 0
              ? relatedArticles.length === 1
                ? "--1"
                : ""
              : posts.edges.length === 1
              ? "--1"
              : ""
          }`}
        >
          {relatedArticles.length > 0
            ? getRelatedArticles(relatedArticles)
            : getBlogArticles(posts)}
        </div>

        {relatedArticles.length === 0 && (
          <Button
            children="Read All Articles"
            variant="outline-secondary"
            extraClassNames="fr-blog-banner__btn"
            to={parentSlug ? `/${parentSlug}` : "/blog"}
          />
        )}
      </div>
    </section>
  )
}

const getBlogBannerFooterPosts = graphql`
  query getBlogBannerFooterPosts {
    posts: allKontentItemBlogPost(
      sort: { fields: elements___published_date___value, order: DESC }
      limit: 2
    ) {
      edges {
        node {
          id
          elements {
            seo_metadata__overlay_cta_text {
              value
            }
            heading {
              value
            }
            featured_text {
              value
            }
            featured_image {
              value {
                description
                height
                name
                url
                width
              }
            }
            published_date {
              value(formatString: "MMMM DD, YYYY")
            }
            slug {
              value
            }
          }
        }
      }
    }
  }
`

export const query = graphql`
  fragment BlogBannerFragment on kontent_item_global_footer_blog_banner {
    id
    elements {
      blog_banner_heading {
        value
      }
      sub_heading {
        value
      }
    }
  }
`

import React, { createContext, useContext } from "react"

type SecondaryHeaderContextData = {
  handleCloseModal: () => void
  handleOpenModal: () => void
  isModalOpen: boolean
}

export const SecondaryHeaderContext = createContext({} as SecondaryHeaderContextData)

export function useSecondaryHeader() {
  const context = useContext(SecondaryHeaderContext)

  if (!context) {
    throw new Error("useSecondaryHeader must be used within SecondaryHeaderContext")
  }

  return context
}

import React, { useMemo } from "react"
import { shuffle } from "../../assets/helper/shuffle"
import { StaticReviewOutput } from "../../contexts/settings/Integrations/integrationsSettings-types"
import { useIntegrationsSettings } from "../../hooks/useIntegrationsSettings"
import { TestimonialBanner } from "../testimonial/TestimonialBanner"

interface StaticReviewProps {
  staticReviews: StaticReviewOutput[]
  isLandingPage: boolean
}

export const StaticReview = ({
  staticReviews,
  isLandingPage,
}: StaticReviewProps) => {
  const { googleReviewsUrl } = useIntegrationsSettings()

  const getRating = (rating: string) => {
    switch (rating) {
      case "n1_star":
        return "★"
      case "n2_stars":
        return "★★"
      case "n3_stars":
        return "★★★"
      case "n4_stars":
        return "★★★★"
      case "n5_stars":
        return "★★★★★"
      default:
        return ""
    }
  }
  const randomReview = useMemo(() => {
    const review = shuffle(staticReviews)
    return review
  }, [staticReviews])

  const {
    rating,
    review,
    reviewer,
    location,
    heading,
    reviewBackgroundImage,
    reviewImage,
  } = randomReview

  const stars = getRating(rating)

  return (
    <TestimonialBanner
      googleReviewUrl={googleReviewsUrl}
      rating={stars}
      reviewerLocation={location}
      review={review}
      reviewerName={reviewer}
      avatar={reviewImage}
      backgroundImageUrl={reviewBackgroundImage?.url}
      heading={heading}
      isLandingPage={isLandingPage}
    />
  )
}

import { useContext } from "react"
import { ColorAreasSettingsContext } from "../contexts/settings/ColorAreas/ColorAreasSettingsContext"
import { ColorAreasSettingsContextData } from "../contexts/settings/ColorAreas/colorAreasSettings-types"

export const useColorAreaSettings = (): ColorAreasSettingsContextData => {
  const context = useContext(ColorAreasSettingsContext)

  if (!context) {
    throw new Error(
      "useColoarAreaSettings must be used within ColorAreaSettingsProvider"
    )
  }

  return context
}
